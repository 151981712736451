import './AddWire.css'
import {wireFields} from "./inputs"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import { Col, DatePicker, Modal, Form, message, Row, Select } from 'antd';
import React, {useCallback, useEffect, useState} from "react";
import CustomTitle from "../../CustomTitle";
import FormInput from "../../input/FormInput";
import { addWireTitle } from '../title';
import FormSelect from '../../select/FormSelect';
import { GetCustomers } from '../../../api/services/userService';
import { User } from '../../../api/requests/userService';
import '../../select/FormSelect.css'
import { CreateWire } from '../../../api/services/wiresService';
import { CreateWireRequest } from '../../../api/requests/wiresService';
import {dateType, selectInput} from "../../../utilities/utilities";
import OperationResult from "../../OperationResult";

const { Option } = Select;

const AddWire: React.FC = ({}) => {
    const [form] = Form.useForm();
    const [customers, setCustomers] = useState<User[]>([]);
    const [status, setStatus] = useState<"success" | "error" | null>(null);

    useEffect(() => {
        GetCustomers("-1", "", "").then(res => {
			if(res && res.customers){
				setCustomers(res.customers)
			}
		})
    }, []);

    const forceCreateWire = useCallback((request: CreateWireRequest) => {
        CreateWire(request).then((res) => {
            if(res && !res.err){
                setStatus("success");
                form.resetFields();
            }else{
                setStatus("error");
            }
        })
    }, []);

    const createWire = useCallback((request: CreateWireRequest) => {
        CreateWire(request).then((res) => {
            if(res && !res.err){
                setStatus("success");
                form.resetFields()
            }else{
                if(res?.err?.code === 13){
                    Modal.confirm({
                        title: 'Il cartellino è già associato ad un altro cavo. Vuoi associare questo cartellino a un nuovo cavo?',
                        onOk() {
                            request.force = true
                            forceCreateWire(request)
                        },
                        okText: 'OK',
                        cancelText: 'Annulla'
                    })
                }else{
                    setStatus("error");
                }
            }
        })
    }, []);

    const ztc_rfid = (request: CreateWireRequest) => {
        if(request.wire_id === ""){
            message.error("Errore di lettura del tag RFID")
        }else{
            createWire(request);
        }
    }

    useEffect( () => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            const ztc_rfid_result = window.api.receive("ztc_device_rfid_result", (result: CreateWireRequest) => ztc_rfid(result))
            return () => {
                ztc_rfid_result()
            }
        }
    }, []);

    const handleRequest = useCallback((req: CreateWireRequest) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            // Electron-specific code
            window.api.send("ztc_device_rfid", [req])
        }else{
            message.error("Il tag RFID del cavo deve essere scansionato dal configurator.")
        }
    }, []);

    const submit = () => {
        form.validateFields().then(
            values => {
                const req: CreateWireRequest = {
                    customer_id: values.customer,
                    name: values.name,
                    model: values.model,
                    description: values.description,
                    wire_id: "",
                    wire_info: {
                        production_date: new Date(values.production_date).toISOString(),
                        description: values.description,
                        diameter: values.diameter,
                        wire_diameter: values.wire_diameter,
                        mount_type: values.mount_type,
                        pearl_meter: values.pearl_meter,
                        length: values.length,
                        wire_type: values.wire_type,
                        max_usage: String(Number(values.max_usage)*60*60),
                        material: values.material
                    }
                }
                Modal.confirm({
                    title: 'Posizionare la card sullo scanner e premere OK per ottenere il tag RFID del cavo.',
                    onOk() {handleRequest(req)},
                    okText: 'OK',
                    cancelText: 'Annulla'
                })
            }
        )
    }

    if(status) {
        return (
            <OperationResult
                status={status}
                operation={"create"}
                entity={"wire"}
            />
        )
    }

    return(
        <>
            <CustomTitle title={addWireTitle} key={"add_wire_title"} />
            <div className="my-AddWire-container my-AddWire-container-responsive" key={"add_installation_div"}>
                <Form layout="vertical" key={1} form={form} name="wire_panel">
                    <Row gutter={24} key={"addWire"}>
                        <Col xs={24} xl={12}>
                            <div className="form__group field" key={"clientidiv"}>
                                <label
                                    key={"customer_label"}
                                    htmlFor="customer"
                                    className="form__label"
                                >
                                    Cliente
                                </label>
                                <Form.Item key={"clientiform"} name={"customer"}>
                                    <Select
                                        showSearch
                                        key={"customer"}
                                        placeholder={"Cliente"}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            String(optionA.children).toLowerCase().localeCompare(String(optionB.children).toLowerCase())
                                        }
                                    >
                                        {
                                            customers.map((el: User, index: number) => {
                                                return (
                                                    <Option value={el.uid} key={index}> {el.email} </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        {
                            wireFields.map((el) => {
                                switch (el.type) {
                                    case selectInput:
                                        return (
                                            <Col xs={24} xl={12} key={`${el.key}_col`}>
                                                <FormSelect
                                                    key={`${el.key}_select`}
                                                    name={el.name}
                                                    keyValue={el.key}
                                                    placeholder={el.label}
                                                    options={el?.options ||  []}
                                                    rules={el.rules}
                                                />
                                            </Col>
                                        )
                                    case dateType:
                                        return (
                                            <Col xs={24} xl={12} key={`${el.key}_col`}>
                                                <div className="form__group field" key={`${el.key}_datadiv`}>
                                                    <label
                                                        key={`${el.key}_label`}
                                                        htmlFor={el.name}
                                                        className="form__label"
                                                    >
                                                        Data Produzione
                                                    </label>
                                                    <Form.Item
                                                        key={el.key}
                                                        name={el.name}
                                                        rules={el.rules}
                                                    >
                                                        <DatePicker name={el.name} style={{width: "100%"}} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        )
                                    default:
                                        return (
                                            <Col xs={24} xl={12} key={`${el.key}_col`}>
                                                <FormInput
                                                    key={`${el.key}_input`}
                                                    name={el.name}
                                                    keyValue={el.key}
                                                    placeholder={el.label}
                                                    type={el.type ?? "text"}
                                                    rules={el.rules}
                                                />
                                            </Col>

                                        )
                                }
                            })
                        }
                    </ Row>
                    <div className="btn-container" key={"add_installation_btn"}>
                        <ButtonConfItem
                            buttonLabel="Reset"
                            buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
                            buttonOnConfirm={() => { form.resetFields(); }}
                        />
                        <ButtonItem
                            buttonType="primary"
                            label="Invio"
                            buttonOnClick={submit}
                        />
                    </div>
                </Form>
            </div>
        </>
    )

}

export default AddWire;