import './charts/timelineChart/timelineChart.css'
import '../../../App.css'
import React, {useEffect, useState} from 'react';
import ResponsiveTable from '../../responsiveTable';
import {History, historyTitle, workingHistoryTableColumn} from './costant';
import {LastWiresElement} from '../../../api/requests/installationsService';
import WiresModal from './summaryCards/WiresModal';
import {getLastUsedWires} from '../../../api/services/timeSeries';
import Title from "antd/es/typography/Title";

type HistoryTableProps = {
    installation_id: string
    history: History[]
}

const HistoryTable: React.FC<HistoryTableProps> = ({installation_id, history}) => {
    const [lastWires, setLastWires] = useState<LastWiresElement[]>([]);
    const [selectedWire, setSelectedWire] = useState<LastWiresElement | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [lastHistory, setLastHistory] = useState<string | null>(null);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    useEffect(() => {
        getLastUsedWires({device_id: installation_id}).then(
            res => {
                if ( res && res.wires ) {
                    setLastWires(res.wires)
                } else {
                    setLastWires([]);
                }
            }
        )
    }, [])

    useEffect(() => {
        if(history.length > 0){
            let maxStart = Math.max.apply(Math, history.map(function (item) {
                return new Date(item.start).getTime()
            }));
            const last = history.filter(item => new Date(item.start).getTime() === maxStart);
            if (last.length === 1 && new Date().getTime() - new Date(last[0].finish).getTime() <= 300000) {
                setLastHistory(new Date(last[0].start).toISOString())
            } else {
                setLastHistory(null);
            }
        } else {
            setLastHistory(null);
        }
    }, [history])

    return (
        <>
            <Title level={4} style={{color: "#089fc7", textAlign: "center"}}> {historyTitle} </Title>
            <ResponsiveTable
                filter={true}
                columns={workingHistoryTableColumn(lastHistory, handleOpen, setSelectedWire, lastWires)}
                data={history}
            />
            <WiresModal open={openModal} handleClose={handleClose} wire={selectedWire} />
        </>
    )
}

export default HistoryTable;