import {GetDataUrl, GetExportInfoUrl, getLastWiresUrl} from "../constants";
import {GetDataRequest, GetExportInfoRequest, GetLastUsedWiresRequest} from "../requests/timeSeries";
import {methodGet, verticalFetch} from "./httpRequests";

export const getData = (req: GetDataRequest) => {
    return verticalFetch(methodGet, GetDataUrl(req))
}

export const getExportInfo = (req: GetExportInfoRequest) => {
    return verticalFetch(methodGet, GetExportInfoUrl(req))
}

export const getLastUsedWires = (req: GetLastUsedWiresRequest) => {
    return verticalFetch(methodGet, getLastWiresUrl(req.device_id))
}