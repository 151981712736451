export const verticalFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    credentials: "include",
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
};

// used to download files
export const blobFetch = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/octet-stream",
    },
  })
    .then((response) => response.blob())
    .catch((error) => {
      console.log(error);
    });
};

export const verticalCheck = (
  method: string,
  url: string,
  param?: string
): Promise<any> => {
  return fetch(url, {
    method: method,
    body: param,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        return response.json();
      } else {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const methodPost = "POST";
export const methodPut = "PUT";
export const methodGet = "GET";
export const methodDelete = "DELETE";

export const generateReport = async () => {
  /*
  fetch("https://reports.mega.diamant.localhost/api/report", {
    method: methodPost,
    credentials: "include",
    body: JSON.stringify({
      template: {
        shortid: "663a2d9c9aaa3ba5676fdf58",
      },
      data: {
        page: 2,
        rows: 20,
        searchTerm: "example",
      },
      options: {
        preview: true,
      },
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  */
};
