import {Action} from "../actions"

export type GlobalState = {
    startHour: number
    x_axis_start: string,
    interval_id: any
}

let d = new Date();
d.setHours(d.getHours() - 24);

export const initialState: GlobalState = {
    startHour: 24,
    x_axis_start: d.toISOString(),
    interval_id: 0
}

const dataChartReducer = (state: GlobalState = initialState, action: Action) => {
    switch(action.type){
        case 'SET_AXIS_DOMAIN':
            return {
                ...state,
                x_axis_start: action.start_date
            }
        case 'SET_START_HOUR':
            return {
                ...state,
                startHour: action.h
            }
        case 'SET_INTERVAL':
            return {
                ...state,
                interval_id: action.interval
            }
        case 'CLEAR_INTERVAL':
            clearInterval(state.interval_id);
            return {
               ...state,
                interval_id: -1
            }
        default:
            return state
    }
}

export default dataChartReducer