import {ResponsiveTableCell, ResponsiveTableColumnType} from "../../responsiveTable";
import {dateTimeType, stringType} from "../../../utilities/utilities";
import { alarm } from "../installation/summaryCards/costant";
import "../../../App.css";

const switchAlarm = (tag: string) => {
    switch(tag){
        case alarm.maxUsageOver90:
            return `Il cavo ha superato la soglia del 90%`
        case alarm.maxUsageOver75:
            return `Il cavo ha superato la soglia del 75%`
        case alarm.moreTwoCards:
            return `La macchina ha lavorato con più di due cartellini`
        case alarm.zeroCards:
            return `La macchina ha lavorato senza cartellini`
        case alarm.unregisteredWire:
            return `Il cartellino non è registrato`
        case alarm.offline:
            return `La macchina è andata offline`
        default:
            return ``
    }
} 

export const alarmColumns: ResponsiveTableColumnType[] = [
    {
        label: "Inizio",
        name: "start",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) => <ResponsiveTableCell type={dateTimeType} value={value}/>
        },
    },
    {
        label: "Fine",
        name: "finish",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value) =>  <ResponsiveTableCell type={value !== "0001-01-01T00:00:00Z" ? dateTimeType : stringType} value={value !== "0001-01-01T00:00:00Z" ? value : ""}/>
        },
    },
    {
        label: "Installazione",
        name: "installation_name",
        options: {
            sort: false,
            filter: true,
            customBodyRender: (value) =>  <ResponsiveTableCell type={"string"} value={value}/>
        },
    },
    {
        label: "Allarme",
        name: "tag",
        options: {
            sort: false,
            filter: true,
            customBodyRender: (value) =>  <ResponsiveTableCell type={"string"} value={switchAlarm(value)} />
        },
    },
    {
        label: "Livello",
        name: "level",
        options: {
            sort: false,
            filter: true,
            customBodyRender: (value) =>  <ResponsiveTableCell type={"tag"} value={value} color={value === "fatal" ? "red" : value === "warning" ? "orange" : "green"} />
        },
    }
]
