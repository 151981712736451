import "./LoginPanel.css"
import "antd/dist/antd.css"
import ButtonItem from "../../ButtonItem"
import { inputs } from "./inputs"
import { Form, Input, message } from "antd"
import {Link, useHistory} from 'react-router-dom'
import React, { useEffect, useState } from "react"
import { login } from "../../../api/services/loginService"
import { LoginRequest } from "../../../api/requests/loginService"
import {routes} from "../costants";
import logo from '../../../logo/logo-wires02.png';


type LoginPanelProps = {
    SetLogged: (logged: boolean) => void
}

const LoginPanel: React.FC<LoginPanelProps> = ({  SetLogged }) => {
    //document.body.style.background = "rgb(250,238,12)"
    document.body.style.background = "radial-gradient(circle, rgba(250,238,12,1) 13%, rgba(245,202,38,1) 32%, rgba(8,159,199,1) 100%)";

    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const setChange = (e: any) =>{
        if(e.target.placeholder==="Email"){
            setEmail(e.target.value)
        }else{
            setPassword(e.target.value)
        }
    }

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [logError, setLogError] = useState(false)

    const history = useHistory();
    const checkLogin = () => {
        login({"email": email, "password": password} as LoginRequest).then(
            res => {
                if(res !== undefined && res.status === "success"){
                    SetLogged(true)
                    history.push("/");
                    document.body.style.background = "white";
                }else{
                    message.error(res?.error || 'Credenziali errate!', 4);
                    setLogError(true)
                    SetLogged(false)
                }
            }
        ).catch((e)=>{
            message.error('Credenziali errate!', 4);
            setLogError(true)
            SetLogged(false)
            console.log(e)
        })
    }

    return (
        <>
        <div className={width <= 768 ? "login-box-responsive" : "login-box"}>
            <div className="image-container">
                <img src={logo} className="image" />
            </div>
            <Form name="normal_login">
                {
                    inputs.map((el)=>{ 
                        return(
                            <Form.Item 
                                key={el.key} 
                                name={el.name} 
                                rules={el.rules}
                                validateStatus= {logError ? "error" : "success"}
                            >
                                <Input
                                    className="my-input"
                                    size="large"
                                    type={el.type ?? "text"}
                                    prefix={el.icon}
                                    placeholder={el.label}
                                    onChange={setChange}
                                />  
                            </Form.Item>
                        )
                    })
                }
                <Link className="login-form-forgot" to={routes.recoverStart}>
                    Reset password
                </Link>
                <div className="btn-container">
                    <Form.Item>    
                        <ButtonItem buttonType="primary" label="Login" buttonOnClick={checkLogin} />
                    </Form.Item>
                </div>
            </Form>
        </div>
        </>
    )
}

export default LoginPanel