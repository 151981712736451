import {Col, DatePicker, Divider, Form, message, Modal, Row} from 'antd';
import React, {useCallback, useEffect, useState} from "react";
import TitleWithBack from '../../../containers/TitleWithBack';
import './UpdateWire.css';
import {updateWireTitle} from "../title";
import {GetWire, UpdateSelectedWire} from "../../../api/services/wiresService";
import {Wire} from '../../types/timeseries/types';
import ButtonItem from '../../ButtonItem';
import ButtonConfItem from '../../ButtonConfItem';
import FormInput from '../../input/FormInput';
import FormSelect from '../../select/FormSelect';
import {wireFields} from './input';
import moment from 'moment';
import {UpdateWireRequest} from '../../../api/requests/wiresService';
import {dateType, selectInput} from "../../../utilities/utilities";
import OperationResult from "../../OperationResult";

type UpdateWireProps = {
    wireId: string
}

const UpdateWire: React.FC<UpdateWireProps> = ({ wireId }) => {
    const [wire, setWire] = useState<Wire | null>(null);
    const [status, setStatus] = useState<"success" | "error" | null>(null);

    useEffect(() => {
        GetWire(wireId).then(res => {
            if (res && res.wire) {
                setWire(res.wire)
            } else {
                setStatus("error");
            }
           
        })
    }, [])

    const forceUpdateWire = useCallback((request: UpdateWireRequest) => {
        UpdateSelectedWire(request).then((res) => {
            if(res && !res.err){
                setStatus("success");
                form.resetFields();
            }else{
                setStatus("error");
            }
        })
    }, []);

    const ztc_rfid = (request: UpdateWireRequest) => {
        if(request.rfid === ""){
            message.error("Errore di lettura del tag RFID")
        }else{
            UpdateSelectedWire(request).then((res) => {
                if(res && !res.err){
                    setStatus("success");
                    form.resetFields();
                }else{
                    if(res?.err?.code === 13){
                        Modal.confirm({
                            title: 'Il cartellino è già associato ad un altro cavo. Vuoi associare questo cartellino a un nuovo cavo?',
                            onOk() {
                                request.force = true
                                forceUpdateWire(request)
                            },
                            okText: 'OK',
                            cancelText: 'Annulla'
                        })
                    }else{
                        setStatus("error");
                    }
                }
            })
        }
    }

    useEffect( () => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            const ztc_rfid_result = window.api.receive("ztc_device_update_rfid_result", (result: UpdateWireRequest) => ztc_rfid(result))
            return () => {
                ztc_rfid_result()
            }
        }
    }, []);

    const handleRequest = useCallback((req: UpdateWireRequest) => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            // Electron-specific code
            window.api.send("ztc_device_update_rfid", [req])
        }else{
            message.error("Il tag RFID del cavo deve essere scansionato dal configurator.")
        }
    }, []);

    const [form] = Form.useForm()

    const submitWithRfid = () => {
        form.validateFields().then(
            values => {
                const req: UpdateWireRequest = {
                    wire_id: wire?.id || "",
                    rfid: values.rfid,
                    name: values.name,
                    description: values.description,
                    model: values.model,
                    wire_info: {
                        production_date: new Date(values.production_date).toISOString(),
                        diameter: values.diameter,
                        mount_type: values.mount_type,
                        length: values.length,
                        wire_diameter: values.wire_diameter,
                        wire_type: values.wire_type,
                        pearl_meter: values.pearl_meter,
                        max_usage: String(Number(values.max_usage)*60*60),
                        material: values.material
                    },
                    force: false
                }
                Modal.confirm({
                    title: 'Posizionare la card sullo scanner e premere OK per ottenere il tag RFID del cavo.',
                    onOk() {handleRequest(req)},
                    okText: 'OK',
                    cancelText: 'Annulla'
                })
            }
        )
    }

    const submit = () => {
        form.validateFields().then(
            values => {
                const req: UpdateWireRequest = {
                    wire_id: wire?.id || "",
                    rfid: "",
                    name: values.name,
                    description: values.description,
                    model: values.model,
                    wire_info: {
                        production_date: values.production_date,
                        diameter: values.diameter,
                        mount_type: values.mount_type,
                        length: values.length,
                        wire_diameter: values.wire_diameter,
                        wire_type: values.wire_type,
                        pearl_meter: values.pearl_meter,
                        max_usage: String(Number(values.max_usage)*60*60),
                        material: values.material
                    },
                    force: false
                }
                UpdateSelectedWire(req).then((res) => {
                    if(res && !res.err){
                        setStatus("success");
                    }else{
                        setStatus("error");
                    }
                })
            }
        )
    }

    if(!wire){
        return null
    }

    if(status) {
        return (
            <OperationResult
                status={status}
                operation={"update"}
                entity={"wire"}
            />
        )
    }
    return(
        <>
            <TitleWithBack title={updateWireTitle} key={"update_wire_title"}/>
            <div className="my-AddWire-container my-AddWire-container-responsive" key={"update_wire_div"}>
                <Form layout="vertical" key={1} form={form} name="wire_panel">
                    <Row gutter={24} key={"updateWire"}>
                        {
                            wireFields(wire).map((el) => {
                                switch (el.type) {
                                    case selectInput:
                                        return (
                                            <Col xs={24} xl={12} key={"col_update_wire" + el.key}>
                                                <FormSelect
                                                    key={el.key + "formselect"}
                                                    name={el.name}
                                                    keyValue={el.key}
                                                    placeholder={el.label}
                                                    options={el?.options ||  []}
                                                    rules={el.rules}
                                                    value={el.value || ""}
                                                />
                                            </Col>
                                        )
                                    case dateType:
                                        return (
                                            <Col xs={24} xl={12} key={"col_update_wire" + el.key}>
                                                <div className="form__group field" key={"datadiv"}>
                                                    <label
                                                        key={el.key + "datalabel"}
                                                        htmlFor="name" className="form__label"
                                                    >
                                                        {"Data Produzione"}</label>
                                                    <Form.Item initialValue={moment(el.value)} key={"dataform"} name={"production_date"} rules={[ {required: true, message: 'Inserire la data!'} ]}>
                                                        <DatePicker key={el.key} name={el.name} style={{width: "100%"}} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        )
                                    default:
                                        return (
                                            <Col xs={24} xl={12} key={"col_update_wire" + el.key}>
                                                <FormInput
                                                    key={el.key + "forminput"}
                                                    name={el.name}
                                                    keyValue={el.key}
                                                    placeholder={el.label}
                                                    type={el.type ?? "text"}
                                                    rules={el.rules}
                                                    value={el.value || ""}
                                                />
                                            </Col>
                                        )

                                }
                            })
                        }
                    </ Row>
                    <div className="btn-container" key={"update_wire_btn"}>
                        <ButtonConfItem buttonLabel="Reset" buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?" />
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={submit} />
                    </div>                    
                    <Divider/>
                    <FormInput
                        key="rifdforminput"
                        name="rfid"
                        keyValue="rfid"
                        placeholder="RFID"
                        type="text"
                        value={wire.rfid}
                        disabled
                    />
                    <div className="btn-container" key={"update_rfid_btn"}>
                        <ButtonItem buttonType="primary" label="Modifica RFID" buttonOnClick={submitWithRfid} />
                    </div>  
                </Form>
            </div>
        </>
    )
    
}

export default UpdateWire;