// types string constants
import moment from "moment";

export const radioType = 'radio';
export const stringType = 'string';
export const usageType = 'usage';
export const numberType = 'number';
export const linkType = 'link';
export const tagType = 'tag';
export const dateType = 'date';
export const dateTimeType = 'datetime';
export const iconType = 'icon';
export const statusDate = 'statusDate';
export const thresholdType = 'threshold';
export const numberInput = "number";
export const selectInput = "select";

// Dates formatters
export const italyTimezone = 'it-IT';
export const localeDateFormatter = (value: string) => {
    const timestamp = Date.parse(value);
    if (!isNaN(timestamp)) {


        return moment(new Date(value)).format("DD/MM/YYYY");
    } else {
        return "---"
    }
};

export const localeDateTimeFormatter = (value: string) => {
    const timestamp = Date.parse(value);
    if (!isNaN(timestamp)) {
        return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
    } else {
        return "---"
    }
}