import { BackendUrl, rowsPerTablePage, WireUrl } from "../constants";
import { CreateWireRequest, UpdateWireRequest } from "../requests/wiresService";
import {
  methodGet,
  methodPost,
  methodPut,
  verticalFetch,
} from "../services/httpRequests";

//Create a new wire
export const CreateWire = (r: CreateWireRequest) => {
  return verticalFetch(
    methodPost,
    `${WireUrl}/${r.wire_id}`,
    JSON.stringify(r)
  );
};

//Get a wire
export const GetWire = (id: string) => {
  return verticalFetch(methodGet, `${WireUrl}/${id}`);
};

//Get all wires
export const GetWires = (page: string, sort: string, search: string) => {
  return verticalFetch(
    methodGet,
    `${WireUrl}?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`
  );
};

//Split an existing wire
export const SplitWire = (id: string) => {
  return verticalFetch(methodPost, `${WireUrl}/${id}/split`);
};

//Modify a wire
export const UpdateSelectedWire = (r: UpdateWireRequest) => {
  return verticalFetch(methodPut, `${WireUrl}/${r.wire_id}`, JSON.stringify(r));
};

//Get storico cavi associati ad un rfid
export const GetRfidWires = (wire_id: string, rfid: string) => {
  return verticalFetch(methodGet, `${WireUrl}/${wire_id}/history/${rfid}`);
};

//Get reports
export const GetWireReports = (
  wireId: string,
  page: string,
  sort: string,
  search: string
) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/reportsmanager/wires/${wireId}`
  );
};

//Disable wire
export const DisableWire = (wireId: string) => {
  return verticalFetch(methodPut, `${WireUrl}/${wireId}/disable`);
};

export const GetWireReport = (wireId: string, reportId: string) => {
  return verticalFetch(
    methodGet,
    `${BackendUrl}/reportsmanager/wires/${wireId}/${reportId}/download`
  );
};
