import "./RecoverPassword.css"
import "antd/dist/antd.css"
import { Result } from "antd"
import React, { useEffect, useState } from "react"
import ButtonItem from "../../ButtonItem"
import { useHistory } from "react-router"
import { loginPageUrl } from "../costants"
import logo from '../../../logo/logo-wires02.png';

type ResultRecoverPasswordProps = {
}

const ResultRecoverPassword: React.FC<ResultRecoverPasswordProps> = () => {
    document.body.style.backgroundColor = "#089fc7"
    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    const history = useHistory()
    const submit = () => {
        history.push(loginPageUrl)
    }

    return (
        <>
            <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
                <div className="image-container">
                    <img src={logo} className="image" />
                </div>
                <Result
                    status="success"
                    title="Password modificata correttamente"
                    subTitle="Adesso puoi effettuare il login con la nuova password."
                    extra={[
                        <ButtonItem buttonType="primary" label="Login" buttonOnClick={submit} />
                    ]}
                />
            </div>
        </>
    )
}

export default ResultRecoverPassword;