import { Badge, Card, Descriptions } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getJob } from "../../../api/services/installationsService";
import TitleWithBack from "../../../containers/TitleWithBack";
import ButtonItem from "../../ButtonItem";
import { updateSignalConfigurationUrl } from "../costants";
import { signalConfigurationTitle } from "../title";
import {
  consumoPotenzaLabel,
  doneStatus,
  failedStatus,
  pendingStatus,
  velocitaTrascinamentoLabel,
  velocitaVolanoLabel,
} from "./costant";

type SignalConfigurationTableProps = {
  installation_id: string;
};

const SignalConfigurationTable: React.FC<SignalConfigurationTableProps> = ({
  installation_id,
}) => {
  const history = useHistory();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [loading, setLoading] = useState<boolean>(true);
  const [initialJob, setInitialJob] = useState({
    config: {
      ta_threshold: "", //consumo potenza
      ta_multi: "", //consumo potenza
      spd_en: "", //velocita volano
      spd_min: "", //velocita volano
      spd_max: "", //velocita volano
      drag_en: "", //velocita trascinamento
      am_en: "", //automatico manuale
    },
    installation_id: "",
    last_set_status: "Mai Effettuato",
  });

  useEffect(() => {
    getJob(installation_id, "calibration").then((res) => {
      if (res && res.calibration) {
        setInitialJob(res.calibration);
      }
      setLoading(false);
    });
  }, []);

  const checkBadgeColor = (last_set_status: string) => {
    switch (last_set_status) {
      case doneStatus:
        return "green";
      case pendingStatus:
        return "yellow";
      case failedStatus:
        return "red";
      default:
        return "blue";
    }
  };

  const checkLastStatus = (last_set_status: string) => {
    switch (last_set_status) {
      case doneStatus:
        return "Eseguito Correttamente";
      case pendingStatus:
        return "In Esecuzione";
      case failedStatus:
        return "Fallito";
      default:
        return "Mai Effettuato";
    }
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={signalConfigurationTitle}
        key={"signal_configuration_title"}
      />
      <Descriptions
        style={{
          marginLeft: width <= 575 || height <= 815 ? "1%" : "20%",
          marginRight: width <= 575 || height <= 815 ? "1%" : "20%",
        }}
        column={1}
        bordered
      >
        <Descriptions.Item label="Stato Calibrazione Segnali">
          <Badge
            color={checkBadgeColor(initialJob.last_set_status)}
            text={checkLastStatus(initialJob.last_set_status)}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className="btn-container" key={"update_signal_configuration_btn"}>
        <ButtonItem
          buttonType="primary"
          label="Modifica"
          buttonOnClick={() => {
            history.push(updateSignalConfigurationUrl(installation_id));
          }}
        />
      </div>
      <div
        className={width <= 575 || height <= 815 ? undefined : "btn-container"}
        key={"update_signal_configuration_badge"}
      >
        <Badge.Ribbon text={consumoPotenzaLabel}>
          <Card
            style={{
              marginTop: "5%",
              height: "150px",
              width: width <= 575 || height <= 815 ? `100%` : "450px",
              border: "1px solid #d9d9d9",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            size="default"
          >
            <p className="p-style">
              Soglia Macchina In Lavorazione:
              {initialJob?.config?.ta_threshold
                ? String(initialJob?.config?.ta_threshold)
                : "---"}
            </p>
            <p className="p-style">
              Macchina trifase:
              {String(initialJob?.config?.ta_multi)
                ? String(initialJob?.config?.ta_multi) === "3"
                  ? "Abilitata"
                  : "Disabilitata"
                : "---"}
            </p>
          </Card>
        </Badge.Ribbon>
        <Badge.Ribbon text={velocitaVolanoLabel}>
          <Card
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "5%",
              height: "150px",
              width: width <= 575 || height <= 815 ? `100%` : "450px",
              border: "1px solid #d9d9d9",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            size="default"
          >
            <p className="p-style">
              Stato Canale:
              {initialJob?.config?.spd_en === "enabled"
                ? "Abilitato"
                : "Disabilitato"}
            </p>
            <p className="p-style">
              Valore Minimo: {String(initialJob?.config?.spd_min) || "---"}
            </p>
            <p className="p-style">
              Valore Massimo: {String(initialJob?.config?.spd_max) || "---"}
            </p>
          </Card>
        </Badge.Ribbon>
      </div>
      <div
        className={width <= 575 || height <= 815 ? undefined : "btn-container"}
        key={"update_signal_configuration_badge"}
      >
        <Badge.Ribbon text={velocitaTrascinamentoLabel}>
          <Card
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "5%",
              height: "100px",
              width: width <= 575 || height <= 815 ? `100%` : "450px",
              border: "1px solid #d9d9d9",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            size="default"
          >
            <p className="p-style">
              Stato Canale:
              {initialJob?.config?.drag_en === "enabled"
                ? "Abilitato"
                : "Disabilitato"}
            </p>
          </Card>
        </Badge.Ribbon>
        <Badge.Ribbon text={"Automatico Manuale"}>
          <Card
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "5%",
              height: "100px",
              width: width <= 575 || height <= 815 ? `100%` : "450px",
              border: "1px solid #d9d9d9",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            size="default"
          >
            <p className="p-style">
              Stato Canale:
              {initialJob?.config?.am_en === "enabled"
                ? "Abilitato"
                : "Disabilitato"}
            </p>
          </Card>
        </Badge.Ribbon>
      </div>
    </>
  );
};

export default SignalConfigurationTable;
