import React from "react";
import {LeftCircleTwoTone} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import '../App.css';
import {Divider} from "antd";
import {useHistory} from "react-router-dom";
import {routes} from "./pages/costants";

type TitleBackProps = {
    title: string
    toHomePage?: boolean
    setSelectedItem: (i: string) => void
}

const TitleWithBack: React.FC<TitleBackProps> = ({ title, toHomePage, setSelectedItem}) => {
    const history = useHistory();
    return (
        <>
        <div className={"titleContainer titleContainerResponsive titleContainerHeigth"}>
            <Title>
                <LeftCircleTwoTone
                    className="back-button"
                    onClick={
                        () => {
                            if(toHomePage) {
                                history.push(routes.subscriptionsListToInstallationPage);
                                setSelectedItem("real_time_data");
                            } else {
                                history.goBack();
                            }
                        }
                    }
                />
            </Title>
            <Title className={"titleBack responsiveTitle"} level={2}>
                {title}
            </Title>
        </div>
        <Divider/>
        </>
    )
}

export default TitleWithBack;