import { GetDataRequest, GetExportInfoRequest } from "./requests/timeSeries"

export const BackendUrl = window._env_.API_ENDPOINT!
export const GrafanaUrl = window._env_.GRAFANA_ENDPOINT!

export const LoginUrl = `${BackendUrl}/auth/login`
export const LogoutUrl = `${BackendUrl}/auth/logout`
export const SetNewPasswordUrl = `${BackendUrl}/auth/recover/end`;
export const ResetPasswordUrl = `${BackendUrl}/auth/recover`
export const GetProfileUrl = `${BackendUrl}/auth/profile`

// TimeSeries service
export const TimeSeriesUrl = `${BackendUrl}/timeseries`
export const getLastWiresUrl = (device_id: string) => `${TimeSeriesUrl}/wires/${device_id}?page=0&rows=${rowsPerTablePage}&sort=-usage_time`

// Installations Service
export const InstallationsServiceUrl = `${BackendUrl}/installations`

// Users Service
export const UsersServiceUrl = `${BackendUrl}/users`

// Subscription Service
export const SubscriptionsUrl = `${BackendUrl}/subscriptions`
export const GetSubscriptionUrl = (subscription_id: string) => {return `${BackendUrl}/subscriptions/${subscription_id}`}
export const UpdateSubscriptionInfoUrl = (subscription_id: string) => {return `${BackendUrl}/subscriptions/${subscription_id}/info`}
export const UpdateSubscriptionStatusUrl = (subscription_id: string) => {return `${BackendUrl}/subscriptions/${subscription_id}/status`}
export const UpdateSubscriptionExpDateUrl = (subscription_id: string) => {return `${BackendUrl}/subscriptions/${subscription_id}/exp`}
export const GetSubscriptionInstallationsUrl = (subscription_id: string, page: string, sort: string, search: string) => {return `${BackendUrl}/subscriptions/${subscription_id}/installations?page=${page}&rows=${rowsPerTablePage}&sort=${sort}&search=${search}`}
export const changeStatusUrl = (subscription_id: string) => {return `${BackendUrl}/subscriptions/${subscription_id}/changestatus`}

export const GetDataUrl = (req: GetDataRequest) => {
    const baseUrl = new URL(`${TimeSeriesUrl}/data/${req?.device_id}/${req?.tag}`)
    baseUrl.searchParams.append("start", req?.start ?? "")
    baseUrl.searchParams.append("end", req?.end ?? "")
    baseUrl.searchParams.append("bucket", req?.bucket ?? "")
    req?.aggregation?.map((value)=>{
        baseUrl.searchParams.append("aggregation", value ?? "")
    })
    baseUrl.searchParams.append("sort", req?.sort ?? "")
    baseUrl.searchParams.append("bucket_gap", "yes")
    return baseUrl.toString()
}

export const GetExportInfoUrl = (req: GetExportInfoRequest) => {
    const baseUrl = new URL(`${TimeSeriesUrl}/data/exports`);
    baseUrl.searchParams.append("start", req.start);
    baseUrl.searchParams.append("end", req.end);
    baseUrl.searchParams.append("entity_id", req.entity_id);
    baseUrl.searchParams.append("tag", req.tag);
    return baseUrl.toString();
}

//Wire service
export const WireUrl = `${BackendUrl}/wires`

export const rowsPerTablePage: number = 50