import 'antd/dist/antd.css';
import { Button } from 'antd';
import React from "react";

type ButtonProps = {
    buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed",
    label: string,
    buttonOnClick: any
    disabled?: boolean
}

const ButtonItem: React.FC<ButtonProps> = ({buttonType, label, buttonOnClick, disabled}) => {
    return(
        <Button 
            disabled={disabled ? disabled : undefined}
            htmlType="submit"
            size="large"
            type={buttonType ?? "default"}
            onClick={buttonOnClick}
        >
            {label}
        </Button>
    )
}

export default ButtonItem;