import { confirmPasswordRules, emailRules, passwordRules } from "../../types/form/rules"
import { InputType } from "../../types/form/types"

export const userFields: InputType[] = [
    {
        key: "name",
        name: "name",
        label: "Nome",
        rules: [ {required: true, message: 'Inserire nome!'} ]
    },
    {
        key: "email",
        name: "email",
        label: "Email",
        rules: emailRules,
        type: "email"
    },
    {
        key: "password",
        name: "password",
        label: "Password",
        rules: passwordRules,
        type: "password"
    },
    {
        key: "confirm_password",
        name: "confirm_password",
        label: "Conferma password",
        rules: confirmPasswordRules,
        type: "password"
    },
    {
        key: "customer_phone",
        name: "customer_phone",
        label: "Telefono",
        path: "phone",
        rules: [ {required: true, message: 'Inserire telefono!'} ],
        type: "number"
    },
    {
        key: "customer_address",
        name: "customer_address",
        label: "Indirizzo",
        path: "address",
        rules: [ {required: true, message: 'Inserire indirizzo!'} ]
    },
    {
        key: "customer_city",
        name: "customer_city",
        label: "Città",
        path: "city",
        rules: [ {required: true, message: 'Inserire città!'} ]
    },
    {
        key: "customer_province",
        name: "customer_province",
        label: "Provincia",
        path: "province",
        rules: [ {required: true, message: 'Inserire provincia!'} ]
    },
    {
        key: "customer_country",
        name: "customer_country",
        label: "Paese",
        path: "country",
        rules: [ {required: true, message: 'Inserire paese!'} ],
        type: "select"
    },
    {
        key: "customer_zip_code",
        name: "customer_zip_code",
        label: "CAP",
        path: "zip_code",
        rules: [ {required: true, message: 'Inserire CAP!'} ]
    }
]
