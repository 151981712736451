import {
  numberType,
  radioType,
  selectInput,
} from "../../../utilities/utilities";
import { select } from "../deviceConnectionConfiguration/costant";

export const Job1 = (j: any) => [
  {
    key: "ta_threshold",
    name: "ta_threshold",
    label: "Soglia Macchina In Lavorazione",
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    value: j.config.ta_threshold,
    type: numberType,
  },
  {
    key: "ta_multi",
    name: "ta_multi",
    label: "Macchina trifase",
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    value: j.config.ta_multi,
    type: radioType,
    option: [
      {
        value: "3",
        label: "Abilitata",
      },
      {
        value: "1",
        label: "Disabilitata",
      },
    ],
  },
];

export const Job2 = (j: any) => [
  {
    key: "spd_en",
    name: "spd_en",
    label: "Stato Canale",
    value: j.config.spd_en,
    type: radioType,
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    option: [
      {
        value: "enabled",
        label: "Abilitato",
      },
      {
        value: "disabled",
        label: "Disabilitato",
      },
    ],
  },
  {
    key: "spd_min",
    name: "spd_min",
    label: "Valore Minimo",
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    value: j.config.spd_min,
    type: numberType,
  },
  {
    key: "spd_max",
    name: "spd_max",
    label: "Valore Massimo",
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    value: j.config.spd_max,
    type: numberType,
  },
];

export const Job3 = (j: any) => [
  {
    key: "drag_en",
    name: "drag_en",
    label: "Stato Canale",
    value: j.config.drag_en,
    type: radioType,
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    option: [
      {
        value: "enabled",
        label: "Abilitato",
      },
      {
        value: "disabled",
        label: "Disabilitato",
      },
    ],
  },
];

export const Job4 = (j: any) => [
  {
    key: "am_en",
    name: "am_en",
    label: "Stato Canale",
    value: j.config.am_en,
    type: radioType,
    rules: [{ required: true, message: "Campo obbligatorio!" }],
    option: [
      {
        value: "enabled",
        label: "Abilitato",
      },
      {
        value: "disabled",
        label: "Disabilitato",
      },
    ],
  },
];

export const consumoPotenzaLabel: string = "Consumo Potenza";
export const velocitaVolanoLabel: string = "Velocità Volano";
export const velocitaTrascinamentoLabel: string = "Velocità Trascinamento";

export const doneStatus: string = "done";
export const pendingStatus: string = "pending";
export const failedStatus: string = "failed";
