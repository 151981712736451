import React from "react";
import '../../navbar/Navbar.css'
import { customersListColumns } from "./input";
import '../../../App.css'
import './UsersListTable.css'
import { GetCustomers } from "../../../api/services/userService";
import CustomTitle from "../../CustomTitle";
import {useHistory} from "react-router-dom";
import ResponsiveTable from "../../responsiveTable";
import { updateUserPageUrl } from "../costants";
import { updateUserTitle } from "../title";

type UsersListTableProps = {
}

const UsersListTable: React.FC<UsersListTableProps> = () => {   

    document.body.style.background = "#f5f5f5c7"

    const history = useHistory();
    const linkFunction: (value: string) => void = (value: string) => { 
        history.push(updateUserPageUrl(value))
    }

    return(
        <>
            <CustomTitle title={updateUserTitle}/>
            <ResponsiveTable 
                columns={customersListColumns(linkFunction)} 
                getPageAndSortedData={GetCustomers}
                defaultSort="name"
                responseDataName="customers" 
            />
        </>
    )
}

export default UsersListTable;