import { InputType } from "../../types/form/types";
import {
  dateType,
  numberType,
  selectInput,
  stringType,
} from "../../../utilities/utilities";

export const mountingOptions = [
  { Molle: "Molle" },
  { Plastica: "Plastica" },
  { Gomma: "Gomma" },
  { "Elettrod.": "Elettrod." },
  { "Sinteriz.": "Sinteriz." },
  { "Saldobr.": "Saldobr." },
];

export const diameterOptions = [
  { "7,3": "7,3" },
  { "8,3": "8,3" },
  { "8,5": "8,5" },
  { "9": "9" },
  { "10": "10" },
  { "10,3": "10,3" },
  { "10,5": "10,5" },
  { "11": "11" },
  { "13": "13" },
  { "15": "15" },
];

export const pearlMeterOptions = [
  { "26": "26" },
  { "27": "27" },
  { "28": "28" },
  { "30": "30" },
  { "32": "32" },
  { "33": "33" },
  { "36": "36" },
  { "39": "39" },
  { "40": "40" },
  { "44": "44" },
];

export const wireTypeOptions = [
  { "61 fili": "61 fili" },
  { "90 fili": "90 fili" },
  { "133 fili": "133 fili" },
];

export const wireDiameterOptions = [
  { "3,5": "3,5" },
  { "3,8": "3,8" },
  { "4,9": "4,9" },
];

export const modelOptions = [
  { "Cave di marmo": "Cave di marmo" },
  { "Squadratura marmi": "Squadratura marmi" },
  { "Sagomatura marmi": "Sagomatura marmi" },
  { "Cave di granito": "Cave di granito" },
  { "Squadratura blocchi di granito": "Squadratura blocchi di granito" },
  { "Sagomatura granito": "Sagomatura granito" },
  { Multifilo: "Multifilo" },
  { "Cemento armato e acciaio": "Cemento armato e acciaio" },
  { "Acciaio e strutture metalliche": "Acciaio e strutture metalliche" },
];

export const materialOptions = [
  { Tenero: "Tenero" },
  { Medio: "Medio" },
  { Duro: "Duro" },
  { Marmo: "Marmo" },
  { Granito: "Granito" },
  { "Calcestruzzo armato": "Calcestruzzo armato" },
  { "Metallo/acciao": "Metallo/acciao" },
  { "Acciao inox": "Acciao inox" },
];

export const wireFields: InputType[] = [
  {
    key: "name",
    name: "name",
    label: "Nome",
    rules: [{ required: true, message: "Inserire nome!" }],
  },
  {
    key: "description",
    name: "description",
    label: "Descrizione",
    rules: [{ required: true, message: "Inserire descrizione!" }],
  },
  {
    key: "model",
    name: "model",
    label: "Modello",
    type: selectInput,
    options: modelOptions,
    rules: [{ required: true, message: "Selezionare modello!" }],
  },
  {
    key: "material",
    name: "material",
    label: "Materiale",
    type: selectInput,
    options: materialOptions,
    rules: [{ required: true, message: "Selezionare materiale!" }],
  },
  {
    key: "production_date",
    name: "production_date",
    label: "Data Produzione",
    type: dateType,
    rules: [{ required: true, message: "Inserire data di produzione!" }],
  },
  {
    key: "length",
    name: "length",
    label: "Lunghezza (m)",
    type: numberType,
    rules: [{ required: true, message: "Inserire lunghezza!" }],
  },
  {
    key: "mount_type",
    name: "mount_type",
    label: "Montaggio",
    type: selectInput,
    options: mountingOptions,
    rules: [{ required: true, message: "Selezionare montaggio!" }],
  },
  {
    key: "diameter",
    name: "diameter",
    label: "Diametro (mm)",
    type: numberType,
    //options: diameterOptions,
    rules: [{ required: true, message: "Selezionare diametro!" }],
  },
  {
    key: "pearl_meter",
    name: "pearl_meter",
    label: "Perle/metro",
    //options: pearlMeterOptions,
    type: numberType,
    rules: [{ required: true, message: "Selezionare perle/metro!" }],
  },
  {
    key: "wire_type",
    name: "wire_type",
    label: "Tipo fune",
    type: selectInput,
    options: wireTypeOptions,
    rules: [{ required: true, message: "Selezionare tipo fune!" }],
  },
  {
    key: "wire_diameter",
    name: "wire_diameter",
    label: "Diametro Fune (mm)",
    type: selectInput,
    options: wireDiameterOptions,
    rules: [{ required: true, message: "Selezionare diametro fune!" }],
  },
  {
    key: "max_usage",
    name: "max_usage",
    label: "Utilizzo Massimo (h)",
    type: numberType,
    rules: [{ required: true, message: "Inserire utilizzo massimo!" }],
  },
  {
    key: "origin",
    name: "origin",
    label: "Provenienza",
    type: stringType,
    rules: [{ required: true, message: "Inserire provenienza!" }],
  },
];
