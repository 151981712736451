import "../../App.css";
import clsx from "clsx";
import React, { useEffect } from "react";
import Navbar from "../../containers/Navbar";
import HeaderItem from "../../containers/HeaderItem";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  dataHistoryPanelUrl,
  installationListToDataHistoryUrl,
  installationListToLineChartUrl,
  installationPageUrl,
  routes,
  updateWireUrl,
} from "./costants";
import Profile from "./profile/Profile";
import CreateInstaller from "../../containers/pages/createInstaller/CreateInstaller";
import CreateCustomer from "../../containers/pages/createCustomer/CreateCustomer";
import InstallationPage from "../../containers/pages/installationPage/InstallationPage";
import CustomerListTable from "../../containers/pages/UserListTable";
import UpdateUserForm from "../../containers/pages/updateUser/UpdateUser";
import InstallerListTable from "../../containers/pages/InstallerTable";
import AddSubscription from "../../containers/pages/addSubscription/AddSubscription";
import UpdateSubscription from "../../containers/pages/updateSubscription/UpdateSubscription";
import InstallerCustomerRelationsPage from "../../containers/pages/InstallerCustomerRelationsPage";
import AddInstallation from "../../containers/pages/addInstallation/AddInstallation";
import InstallationListPage from "../../containers/pages/installationsListPages/InstallationListPage";
import UpdateInstallation from "../../containers/pages/updateInstallation/UpdateInstallation";
import DataHistoryPanel from "../../containers/pages/dataExportDownload/DataHistoryPanel";
import DeviceConnectionConfiguration from "../../containers/pages/deviceConnectionConfiguration/DeviceConnectionConfiguration";
import InstallationListSubscriptionSummary from "../../containers/pages/installationsListPages/InstallationsListSubscriptionSummary";
import { installationsListColumns } from "./installationsList/inputs";
import SubscriptionListToUpdate from "../../containers/pages/SelectSubscription";
import SubscriptionTable from "../../containers/pages/subscriptionsListPage/SubscriptionTable";
import { GetUserProfile } from "../../api/services/userService";
import AddWire from "./addwire/AddWire";
import UpdateWire from "./updatesWire/UpdateWire";
import SignalConfiguration from "./signalConfiguration/signalConfiguration";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useStyles } from "./muiStyle";
import HomePage from "../../containers/pages/HomePage";
import AdminPanel from "./homePages/adminPanel";
import ConfiguratorPanel from "./homePages/configuratorPanel";
import SignalConfigurationTable from "./signalConfiguration/signalConfigurationTable";
import AlarmTable from "../../containers/pages/alarmsPage/AlarmTable";
import WireListTable from "../../containers/pages/wireList/WireListTable";
import Report from "./report/Report";

const InstallationsListToData = (props: any) => {
  const { id } = props.match.params;
  return (
    <InstallationListSubscriptionSummary
      subscription_id={id}
      columns={installationsListColumns}
      redirectUrl={installationPageUrl}
    />
  );
};

const InstallationsListToHistory = (props: any) => {
  const { id } = props.match.params;
  return (
    <InstallationListSubscriptionSummary
      subscription_id={id}
      columns={installationsListColumns}
      redirectUrl={dataHistoryPanelUrl}
    />
  );
};

const InstallationPageView = (props: any) => {
  const { id } = props.match.params;
  return <InstallationPage installation_id={id} />;
};

const UpdateInstallationView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateInstallation installationId={id} />;
};

const UpdateSubscriptionView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateSubscription subscriptionId={id} />;
};

const SubListToUpdateView = () => {
  return <SubscriptionListToUpdate />;
};

const SubListToInstallationView = () => {
  return <SubscriptionTable link={installationListToLineChartUrl} />;
};

const SubListToDataHistory = () => {
  return <SubscriptionTable link={installationListToDataHistoryUrl} />;
};

const DataHistoryView = (props: any) => {
  const { id } = props.match.params;
  return <DataHistoryPanel installation_id={id} />;
};

const WireListTableView = () => {
  return <WireListTable link={updateWireUrl} />;
};

const ReportView = () => {
  return <Report />;
};

const UpdateWireView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateWire wireId={id} />;
};

const UpdateUserView = (props: any) => {
  const { id } = props.match.params;
  return <UpdateUserForm user_id={id} />;
};

const SignalConfigurationView = (props: any) => {
  const { id } = props.match.params;
  return <SignalConfigurationTable installation_id={id} />;
};

const UpdateSignalConfigurationView = (props: any) => {
  const { id } = props.match.params;
  return <SignalConfiguration installation_id={id} />;
};

type MainPageProps = {
  role: string;
  navbar_collapsed: boolean;
};

const DeviceConnectionConfigurationView = (props: any) => {
  const { id } = props.match.params;
  return <DeviceConnectionConfiguration installation_id={id} />;
};

const MainPage: React.FC<MainPageProps> = () => {
  document.body.style.backgroundColor = "white";
  const history = useHistory();

  useEffect(() => {
    GetUserProfile().then((res) => {
      if (!res || !res.user) {
        history.push("/login");
      }
    });
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Navbar />
      <HeaderItem />
      <div
        className={
          isMobile
            ? clsx(classes.content)
            : clsx(classes.content, { [classes.contentShift]: true })
        }
      >
        <Switch>
          <Route exact path={routes.mainPage} component={HomePage} />
          <Route exact path={routes.profile} component={Profile} />
          <Route exact path={routes.adminPanel} component={AdminPanel} />
          <Route
            exact
            path={routes.configuratorPanel}
            component={ConfiguratorPanel}
          />
          <Route exact path={routes.addInstaller} component={CreateInstaller} />
          <Route exact path={routes.addCustomer} component={CreateCustomer} />
          <Route
            exact
            path={routes.installationPage}
            component={InstallationPageView}
          />
          <Route
            exact
            path={routes.customersList}
            component={CustomerListTable}
          />
          <Route exact path={routes.updateUser} component={UpdateUserView} />
          <Route
            exact
            path={routes.installersList}
            component={InstallerListTable}
          />
          <Route
            exact
            path={routes.installationsListToLineChart}
            component={InstallationsListToData}
          />
          <Route
            exact
            path={routes.installationsListToDataHistory}
            component={InstallationsListToHistory}
          />
          <Route
            exact
            path={routes.addSubscription}
            component={AddSubscription}
          />
          <Route
            exact
            path={routes.subscriptionsListToUpdate}
            component={SubListToUpdateView}
          />
          <Route
            exact
            path={routes.updateSubscription}
            component={UpdateSubscriptionView}
          />
          <Route
            exact
            path={routes.subscriptionsListToInstallationPage}
            component={SubListToInstallationView}
          />
          <Route
            exact
            path={routes.subscriptionsListToDataHistory}
            component={SubListToDataHistory}
          />
          <Route
            exact
            path={routes.installersAssociations}
            component={InstallerCustomerRelationsPage}
          />
          <Route
            exact
            path={routes.addInstallation}
            component={AddInstallation}
          />
          <Route
            exact
            path={routes.installationsListToUpdate}
            component={InstallationListPage}
          />
          <Route
            exact
            path={routes.updateInstallation}
            component={UpdateInstallationView}
          />
          <Route exact path={routes.alarms} component={AlarmTable} />
          <Route
            exact
            path={routes.dataHistoryPanel}
            component={DataHistoryView}
          />
          <Route
            exact
            path={routes.deviceConnectionConfiguration}
            component={DeviceConnectionConfigurationView}
          />
          <Route exact path={routes.addWire} component={AddWire} />
          <Route exact path={routes.wiresList} component={WireListTable} />
          <Route
            exact
            path={routes.wireListToUpdateWire}
            component={WireListTableView}
          />
          <Route exact path={routes.updateWire} component={UpdateWireView} />
          <Route
            exact
            path={routes.signalConfiguration}
            component={SignalConfigurationView}
          />
          <Route
            exact
            path={routes.updateSignalConfiguration}
            component={UpdateSignalConfigurationView}
          />
          <Route exact path={routes.report} component={ReportView} />
        </Switch>
      </div>
    </>
  );
};

export default MainPage;
