import { connect } from 'react-redux'
import { Store } from '../reducers'
import {Dispatch} from 'react'
import {Action} from '../actions'
import HeaderItem from '../components/header/Header'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        SetNavbarIsCollapsed: (collapsed: boolean) => dispatch({type: "SET_NAVBAR_IS_COLLAPSED", collapsed: collapsed}),
        SetLogged: (logged: boolean) => dispatch({type: "SET_LOGGED", logged: logged})
    }
}

const mapStateToProps = (state: Store) => {
    return {
        navbar_collapsed: state.info.navbar_collapsed
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderItem)