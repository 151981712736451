import { Card, Modal } from 'antd';
import React, { useEffect, useState } from "react";
import './UpdateInstallation.css'
import Meta from "antd/lib/card/Meta";
import { SettingOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';
import {  signalConfigurationUrl } from '../costants';

type CardOptionProps = {
    installationId: string
}

const CardOption: React.FC<CardOptionProps> = ({ installationId }) => {
    const history = useHistory();
    const [electron, setElectron] = useState(false)

    useEffect(() => {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            setElectron(true)
        }else{
            setElectron(false)
        }
    }, [])

    const logError = () => {
        return (
            Modal.error({
                title: 'Per eseguire questa operazione utilizzare il configurator.'
            })
        ) 
    }

    return(
        <>
            <Card
                hoverable
                bordered
                className="card"
                actions={[
                    <p className="card-opt" onClick={()=> electron ? history.push(`/installations/${installationId}/configuration`): logError()}> Configurazione dispositivo </p>,
                    <p className="card-opt" onClick={() => history.push(signalConfigurationUrl(installationId))}> Calibrazione segnali </p>, 
                    //<p className="card-opt" onClick={() => history.push(configurationAlarmsPageUrl(installationId))}> Calibrazione allarmi </p>
                    ]}
            >
                <Meta
                    avatar={<SettingOutlined />}
                    title="Opzioni installazione"
                    description="Selezionare l'opzione desiderata"
                />
            </Card>
        </>
    )
}

export default CardOption;