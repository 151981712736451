import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import { Store } from '../../../reducers';
import InstallationPage from '../../../components/pages/installation/InstallationPage';

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            clearDataInterval: () => dispatch({type: 'CLEAR_INTERVAL'}),
            setStartHour: (h: number) => dispatch({type: 'SET_START_HOUR', h: h}),
            setStartDate: (start_date: string) => dispatch({type: 'SET_AXIS_DOMAIN', start_date: start_date})
        },
        dispatch,
    );
}

const mapStateToProps = (state: Store) => {
    return {
        startHour: state.chart.startHour,
        role: state.info.role,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationPage)