import { InputType } from "../../types/form/types";

export const installationFields: InputType[] = [
    {
        key: "name",
        name: "name",
        label: "Nome",
        rules: [ {required: true, message: 'Inserire nome!'} ]
    },
    {
        key: "description",
        name: "description",
        label: "Descrizione",
        rules: [ {required: true, message: 'Inserire descrizione!'} ]
    }
]
