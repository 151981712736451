import { GrafanaUrl } from "../../api/constants";

export const idParam = ":id";
export const nameParam = ":name";

export const resetPasswordPageUrlResult = `/reset-result`;
export const recoverPageUrlResult = `/recover-result`;
export const mainUrl = `/`;
export const profileUrl = `/profile`;
export const loginPageUrl = `/login`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;
export const createInstallerPageUrl = `/installers/new`;
export const createCustomerPageUrl = `/customers/new`;
export const customersListPageUrl = `/customers`;
export const installersListPageUrl = `/installers`;
export const updateUserPageUrl = (id: string) => `/users/update/${id}`;
export const createSubscriptionPageUrl = `/subscriptions/new`;
export const installationPageUrl = (id: string) => `/installations/${id}`;
export const subscriptionsListToInstallationPageUrl = `/data/subscriptions`;
export const installationListToLineChartUrl = (id: string) =>
  `/data/subscriptions/${id}/installations`;
export const subscriptionsListToUpdateUrl = `/subscriptions/update`;
export const updateSubscriptionUrl = (id: string) =>
  `/subscriptions/update/${id}`;
export const subscriptionsListToDataHistoryPageUrl = `/history/subscriptions`;
export const installationListToDataHistoryUrl = (id: string) =>
  `/history/subscriptions/${id}/installations`;
export const dataHistoryPanelUrl = (id: string) =>
  `/history/subscriptions/installations/${id}`;
export const createInstallationUrl = `/new_installation`;
export const installersAssociationsUrl = `/subscriptions/installers`;
export const installationsListToUpdateUrl = `/subscriptions/installations`;
export const updateInstallationUrl = (id: string) =>
  `/installations/${id}/update`;
export const alarmsPageUrl = `/alarms`;
export const deviceConnectionConfigurationUrl = (id: string) =>
  `/installations/${id}/configuration`;
export const addWireUrl = `/new_wire`;
export const wiresListUrl = `/wires`;
export const reportUrl = `/report`;
export const adminPanelIconsUrl = `/adminpanel`;
export const installerPanelIconsUrl = `/installerpanel`;
export const wireListToUpdateWireUrl = `/wires/update`;
export const updateWireUrl = (id: string) => `/wires/update/${id}`;
export const configurationAlarmsPageUrl = (id: string) =>
  `/${id}/alarms/configuration`;
export const updateAlarmPageUrl = (id: string, name: string) =>
  `/alarms/${id}/update/${name}`;
export const signalConfigurationUrl = (id: string) =>
  `/${id}/signal/configuration`;
export const updateSignalConfigurationUrl = (id: string) =>
  `/${id}/signal/configuration/update`;

export const routes = {
  mainPage: mainUrl,
  adminPanel: adminPanelIconsUrl,
  configuratorPanel: installerPanelIconsUrl,
  login: loginPageUrl,
  recoverStart: resetPasswordPageUrl,
  recoverEnd: recoverPageUrl,
  resetResult: resetPasswordPageUrlResult,
  recoverResult: recoverPageUrlResult,
  profile: profileUrl,
  addInstaller: createInstallerPageUrl,
  addCustomer: createCustomerPageUrl,
  installationPage: installationPageUrl(idParam),
  customersList: customersListPageUrl,
  installersList: installersListPageUrl,
  updateUser: updateUserPageUrl(idParam),
  installationsListToLineChart: installationListToLineChartUrl(idParam),
  installationsListToDataHistory: installationListToDataHistoryUrl(idParam),
  addSubscription: createSubscriptionPageUrl,
  subscriptionsListToUpdate: subscriptionsListToUpdateUrl,
  updateSubscription: updateSubscriptionUrl(idParam),
  subscriptionsListToDataHistory: subscriptionsListToDataHistoryPageUrl,
  dataHistoryPanel: dataHistoryPanelUrl(idParam),
  subscriptionsListToInstallationPage: subscriptionsListToInstallationPageUrl,
  installersAssociations: installersAssociationsUrl,
  addInstallation: createInstallationUrl,
  installationsListToUpdate: installationsListToUpdateUrl,
  updateInstallation: updateInstallationUrl(idParam),
  alarms: alarmsPageUrl,
  deviceConnectionConfiguration: deviceConnectionConfigurationUrl(idParam),
  addWire: addWireUrl,
  wiresList: wiresListUrl,
  report: reportUrl,
  wireListToUpdateWire: wireListToUpdateWireUrl,
  updateWire: updateWireUrl(idParam),
  alarmsConfiguration: configurationAlarmsPageUrl(idParam),
  alarmsUpdate: updateAlarmPageUrl(idParam, nameParam),
  signalConfiguration: signalConfigurationUrl(idParam),
  updateSignalConfiguration: updateSignalConfigurationUrl(idParam),
};

export const roles = {
  admin: "admin",
  installer: "installer",
  customer: "customer",
};

export const rowsPerTablePage: number = 15;
export const refreshTime: string = "5m";
export const iFrameUrl = (device_id: string) =>
  `${GrafanaUrl}/d/f9s0fSWnk/megadiamant?orgId=1&var-device_id=${device_id}&refresh=${refreshTime}&kiosk=tv`;
