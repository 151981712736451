import { LastWiresElement } from "../../../../api/requests/installationsService"
import { dateType, localeDateFormatter, stringType } from "../../../../utilities/utilities"
import { ResponsiveTableCell } from "../../../responsiveTable"
import { toHHMMSS } from "./WiresColumns"

export type DateType = {
    value: number
    label: string
    period: PeriodType[]
}

export type PeriodType = {
    value: string
    label: string
}

export type Usage = {
    cards_count: string
    device_id: string
    duration: number
}

export const wiresModalComponents = (wire: any) => [
    {
        key: "name",
        title: "Nome",
        property: wire.name
    },
    {
        key: "model",
        title: "Modello",
        property: wire.model
    },
    {
        key: "rfid",
        title: "RFID",
        property: wire.rfid
    },
    {
        key: "material",
        title:"Materiale",
        property: wire.wire_info.material
    },
    {
        key: "diameter",
        title: "Diametro",
        property: wire.wire_info.diameter
    },
    {
        key: "max_usage",
        title: "Tempo massimo (h)",
        property: String(Number(wire.wire_info.max_usage)/3600)
    },
    {
        key: "wire_type",
        title: "Tipo",
        property: wire.wire_info.wire_type
    },
    {
        key: "mount_type",
        title: "Montaggio",
        property: wire.wire_info.mount_type
    },
    {
        key: "description",
        title: "Descrizione",
        property: wire.wire_info.description
    },
    {
        key: "pearl_meter",
        title: "Perle/metro",
        property: wire.wire_info.pearl_meter
    },
    {
        key: "length",
        title: "Lunghezza (m)",
        property: wire.wire_info.length
    },
    {
        key: "wire_diameter",
        title: "Diametro (mm)",
        property: wire.wire_info.wire_diameter
    },
    {
        key: "production_date",
        title: "Data Produzione",
        property: localeDateFormatter(wire.wire_info.production_date)
    },
    {
        key: "usage_time",
        title: "Tempo di Utilizzo (hh:mm)",
        property: toHHMMSS((Number(wire.usage_time)).toString())
    },
    {
        key: "customer",
        title: "Cliente",
        property: wire.last_customer_name + " - " + wire.last_customer_email
    }
]

export const alarm = {
    zeroCards: "no_cards",
    moreTwoCards: "more_two_cards",
    maxUsageOver90: "max_usage_over_90",
    maxUsageOver75: "max_usage_over_75",
    unregisteredWire: "unregistered_wire",
    offline: "offline"
}

export const rfidModalComponents = [
    {
        label: "Nome",
        name: "name",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell type={stringType} value={value}/>
        }
    },
    {
        label: "RFID",
        name: "rfid",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell type={stringType} value={value}/>
        }
    },
    {
        label: "Data Creazione",
        name: "created_at",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: string) => <ResponsiveTableCell type={dateType} value={value}/>
        }
    }
]