import './Navbar.css'
import {adminMenuItems} from './adminMenuItems'
import {installerMenuItems} from './installerMenuItems'
import {customerMenuItems} from './customerMenuItems'
import React, {useEffect, useState} from "react";
import {GetUserProfile} from '../../api/services/userService';
import {MenuType, SubMenuType} from '../types/navbar/types';
import {useHistory} from "react-router-dom";
import logo from '../../logo/logo-wires02.png';
import {Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {routes} from "../pages/costants";

type NavbarProps = {
	SetRole: (role: string) => void
	SetNavbarIsCollapsed: (collapsed: boolean) => void
	navbar_collapsed: boolean,
	selected: string,
	SetItem: (s: string) => void,
	panel_open: boolean,
	SetPanelIsOpen: (o: boolean) => void
}

const Navbar: React.FC<NavbarProps> = ({
	SetRole,
	SetNavbarIsCollapsed,
	navbar_collapsed,
    panel_open,
	SetPanelIsOpen,
	SetItem,
	selected
}) => {

	const [menuItem, setMenuItem] = useState<MenuType[]>([]);
	const history = useHistory();

    const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

	useEffect(() => {
        GetUserProfile().then(res => {
			if(res && res.user && res.user.roles && res.user.roles.length > 0){
				const role: string = res["user"]["roles"][0]
				if(role.includes("installer")){
					SetRole("installer")
					setMenuItem(installerMenuItems)
				}else{
					if(role.includes("admin")){
						SetRole("admin")
						setMenuItem(adminMenuItems)
					}else{
						SetRole("viewer")
						setMenuItem(customerMenuItems)
					}
				}
			}
		})
    }, [])

	return (
		<>
			<Drawer
				key="drawer-navbar"
				onClose={() => SetNavbarIsCollapsed(true)}
				open={isMobile ? !navbar_collapsed : true}
				variant={!isMobile ? "persistent" : undefined}
			>
				<img
					key="logo"
					className={'logo'}
					alt="logo"
					src={logo}
					onClick={
						() => {
							history.push(routes.mainPage)
							SetNavbarIsCollapsed(true);
					}}
				/>
				<List key="list-navbar">
					{
					menuItem.map((item: MenuType) => {
						return(
							<div key={item.key + "div"}>
								<ListItem
									button
									key={item.key}
									onClick={() => {
										if(item?.link){
											if (typeof item.link === "string") {
												history.push(item.link)
											} else{
												history.push(item.link());
											}
										}
										if(item.elements.length > 0) {
											SetPanelIsOpen(!panel_open);
										} else {
											SetItem(item.key);
											SetNavbarIsCollapsed(true);
										}
									}}
									selected={selected === item.key}
								>
									<ListItemIcon key={`${item.key}_icon`}>
										{item.icon}
									</ListItemIcon>
									<ListItemText key={item.key + "text"} primary={item.label}/>
									{item.elements.length > 0 ? panel_open ? <ExpandLess /> : <ExpandMore /> : null}
								</ListItem>
								{
									item.elements.length > 0 ?
										<Collapse
											key={item.key + "collapse"}
											in={panel_open}
											timeout="auto"
											unmountOnExit
										>
											<List key={`${item.key}_secondary_list`} component="div" disablePadding>
												{
													item.elements.map((e: SubMenuType) =>
														<ListItem
															key={e.key}
															selected={selected === e.key}
															button
															onClick={() => {
																if(e?.link){
																	SetItem(e.key);
																	if (typeof e.link === "string") {
																		history.push(e.link)
																	} else{
																		history.push(e.link());
																	}
																	SetNavbarIsCollapsed(true)
																}
															}}
														>
															<ListItemIcon key={`${e.key}_icon`}>
																{e.icon}
															</ListItemIcon>
															<ListItemText key={e.key + "text"} primary={e.label} />
														</ListItem>
													)
												}
											</List>
										</Collapse>
									:
									null
								}
							</div>
						)
					})
					}
				</List>
        	</Drawer>
		</>
	)
}

export default Navbar;