import "./summary.css";
import React from "react";
import { Descriptions } from "antd";
import { Installation } from "../../api/requests/installationsService";
import moment from "moment";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YY");
  };

  return (
    <div className="description">
      <Descriptions
        labelStyle={{ fontWeight: "bold" }}
        style={{ marginTop: "2%", marginBottom: "2%" }}
        title="Installazione"
        bordered
      >
        <Descriptions.Item label="Nome" span={3}>
          {selected_installation?.name ?? "---"}
        </Descriptions.Item>
        <Descriptions.Item label="Descrizione" span={3}>
          {selected_installation?.description ?? "---"}
        </Descriptions.Item>
        <Descriptions.Item label="Data Creazione" span={3}>
          {selected_installation?.created_at
            ? dateFormatter(selected_installation?.created_at)
            : "---"}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default InstallationSummary;
