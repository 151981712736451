import {
  addInstallationTitle,
  addInstallerTitle,
  addSubscriptionTitle,
  addUserTitle,
  addWireTitle,
  adminPanelTitle,
  alarmsTitle,
  associationsInstallersTitle,
  ConfiguratorTitle,
  dataHistoryTitle,
  dataRealTimeTitle,
  ReportTitle,
  updateInstallationTitle,
  updateInstallerTitle,
  updateSubscriptionTitle,
  updateUserTitle,
  updateWireTitle,
  wiresListTitle,
} from "../title";

import data from "./img/graph.png";
import download from "./img/cloud-download.png";
import wires from "./img/cavo.png";
import configurator from "./img/inst.png";
import admin from "./img/admin.png";
import installer from "./img/installer.png";
import user from "./img/user.png";
import people from "./img/people.png";
import change from "./img/change.png";
import device from "./img/device.png";
import alarm from "./img/alert.png";

import { routes } from "../costants";

export type IconCard = {
  img: string;
  text: string[];
  link: string[];
};

export const roleBasedIcons: (r: string) => IconCard[] = (r) => {
  if (r === "installer") {
    return [
      {
        img: configurator,
        text: [ConfiguratorTitle],
        link: [routes.configuratorPanel],
      },
    ];
  } else if (r === "admin") {
    return [
      {
        img: admin,
        text: [adminPanelTitle],
        link: [routes.adminPanel],
      },
    ];
  } else {
    return [
      {
        img: device,
        text: [ReportTitle],
        link: [routes.report],
      },
    ];
  }
};

export const homePageIconCards: (r: string) => IconCard[] = (r) => [
  {
    img: data,
    text: [dataRealTimeTitle],
    link: [routes.subscriptionsListToInstallationPage],
  },
  {
    img: download,
    text: [dataHistoryTitle],
    link: [routes.subscriptionsListToDataHistory],
  },
  {
    img: alarm,
    text: [alarmsTitle],
    link: [routes.alarms],
  },
  {
    img: wires,
    text: [wiresListTitle],
    link: [routes.wiresList],
  },
  ...roleBasedIcons(r),
];

export const adminPanelIconCards: IconCard[] = [
  {
    img: device,
    text: [ReportTitle],
    link: [routes.report],
  },
  {
    img: installer,
    text: [addInstallerTitle, updateInstallerTitle],
    link: [routes.addInstaller, routes.installersList],
  },
  {
    img: people,
    text: [associationsInstallersTitle],
    link: [routes.installersAssociations],
  },
  {
    img: change,
    text: [updateSubscriptionTitle],
    link: [routes.subscriptionsListToUpdate],
  },
  {
    img: wires,
    text: [addWireTitle, updateWireTitle],
    link: [routes.addWire, routes.wireListToUpdateWire],
  },
  {
    img: user,
    text: [updateUserTitle],
    link: [routes.customersList],
  },
];

export const configuratorPanelIconCards: IconCard[] = [
  {
    img: user,
    text: [addUserTitle, updateUserTitle],
    link: [routes.addCustomer, routes.customersList],
  },
  {
    img: change,
    text: [addSubscriptionTitle, updateSubscriptionTitle],
    link: [routes.addSubscription, routes.subscriptionsListToUpdate],
  },
  {
    img: device,
    text: [addInstallationTitle, updateInstallationTitle],
    link: [routes.addInstallation, routes.installationsListToUpdate],
  },
];
