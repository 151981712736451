import { MenuType } from "../types/navbar/types";
import {
  alarmsPageUrl,
  reportUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  wiresListUrl,
} from "../pages/costants";
import {
  ReportTitle,
  alarmsTitle,
  dataHistoryTitle,
  dataRealTimeTitle,
  wiresListTitle,
} from "../pages/title";
import dataRealTime from "../pages/homePages/img/graph_black.png";
import dataHistory from "../pages/homePages/img/cloud-download_black.png";
import wires from "../pages/homePages/img/cavo_black.png";
import alarm from "../pages/homePages/img/alert_black.png";
import device from "../pages/homePages/img/device_black.png";

export const customerMenuItems: MenuType[] = [
  {
    key: "customer",
    label: dataRealTimeTitle,
    icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"} />,
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: "history",
    label: dataHistoryTitle,
    icon: <img alt={"--"} src={dataHistory} className={"menu-icon"} />,
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: "alarms",
    label: alarmsTitle,
    link: alarmsPageUrl,
    icon: <img alt={"--"} src={alarm} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "wires",
    label: wiresListTitle,
    link: wiresListUrl,
    icon: <img alt={"--"} src={wires} style={{ width: "30px" }} />,
    elements: [],
  },
  {
    key: "report",
    label: ReportTitle,
    link: reportUrl,
    icon: <img alt={"--"} src={device} style={{ width: "30px" }} />,
    elements: [],
  },
];
