import "./RecoverPassword.css"
import "antd/dist/antd.css"
import ButtonItem from "../../ButtonItem"
import { inputs } from "./inputs"
import { Form, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import queryString from "querystring";
import {RouteComponentProps} from "react-router";
import {recoverPassword} from "../../../api/services/loginService";
import {useHistory} from "react-router-dom";
import { recoverPageUrlResult } from "../costants"
import logo from '../../../logo/logo-wires02.png';


const RecoverPassword: React.FC<RouteComponentProps> = (props) => {
    document.body.style.background = "radial-gradient(circle, rgba(250,238,12,1) 13%, rgba(245,202,38,1) 32%, rgba(8,159,199,1) 100%)";
    const [token, setToken] = useState<string|string[]>("")
    const [width, setWidth] = useState<number>(window.innerWidth);
    const history = useHistory();


	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    useEffect(() => {
        const params = queryString.parse(props.location.search)
        if (params['?token']) {
            setToken(params['?token'])
        } else {
            setToken('')
        }
    }, [props.location.search])

    const [form] = Form.useForm();

    const submit = () => {
        form.validateFields().then(
            values => {
                recoverPassword({password: values.password, confirm_password: values.confirm_password, token: token}).then(
                    (response) => {
                        if (response.status === "success") {
                            history.push(recoverPageUrlResult)
                        }else{
                            message.error("Qualcosa è andato storto, la password non è stata modificata correttamente.")
                        }
                    }
                ).catch(
                    e => {
                        console.log("error:", e)
                    }
                )
            }
        )
    }

    return (
        <>
        <div className={width <= 768 ? "recover-box-responsive" : "recover-box"}>
            <div className="image-container">
                <img src={logo} className="image" />
            </div>
            <Form name="normal_login" form={form}>
                <h2> Inserisci la nuova password </h2>
                {
                    inputs.map((el)=>{ 
                        return(
                            <Form.Item key={el.key} name={el.name} rules={el.rules}>
                                <Input
                                    size="large"
                                    type={el.type ?? "text"}
                                    prefix={el.icon}
                                    placeholder={el.label}
                                />  
                            </Form.Item>
                        )
                    })
                }
                <div className="recover-btn-container">
                    <Form.Item>
                        <ButtonItem
                            buttonType="primary"
                            label="Conferma"
                            buttonOnClick={submit}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
        </>
    )
}

export default RecoverPassword;