import { MenuType, SubMenuType } from "../types/navbar/types";
import {
  addWireUrl,
  adminPanelIconsUrl,
  alarmsPageUrl,
  createInstallerPageUrl,
  customersListPageUrl,
  installersAssociationsUrl,
  installersListPageUrl,
  reportUrl,
  subscriptionsListToDataHistoryPageUrl,
  subscriptionsListToInstallationPageUrl,
  subscriptionsListToUpdateUrl,
  wireListToUpdateWireUrl,
  wiresListUrl,
} from "../pages/costants";
import {
  ReportTitle,
  addInstallerTitle,
  addWireTitle,
  adminPanelTitle,
  alarmsTitle,
  associationsInstallersTitle,
  dataHistoryTitle,
  dataRealTimeTitle,
  updateInstallerTitle,
  updateSubscriptionTitle,
  updateUserTitle,
  updateWireTitle,
  wiresListTitle,
} from "../pages/title";
import dataRealTime from "../pages/homePages/img/graph_black.png";
import dataHistory from "../pages/homePages/img/cloud-download_black.png";
import wires from "../pages/homePages/img/cavo_black.png";
import admin from "../pages/homePages/img/admin_black.png";
import device from "../pages/homePages/img/device_black.png";
import installer from "../pages/homePages/img/installer_black.png";
import change from "../pages/homePages/img/change_black.png";
import people from "../pages/homePages/img/people_black.png";
import user from "../pages/homePages/img/user_black.png";
import alarm from "../pages/homePages/img/alert_black.png";

const adminItem: SubMenuType[] = [
  {
    key: "report",
    label: ReportTitle,
    link: reportUrl,
    icon: <img alt={"--"} src={device} style={{ width: "30px" }} />,
    elements: [],
  },
  {
    key: "create_installer",
    label: addInstallerTitle,
    link: createInstallerPageUrl,
    icon: <img alt={"--"} src={installer} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "update_installer",
    label: updateInstallerTitle,
    icon: <img alt={"--"} src={installer} className={"menu-icon"} />,
    link: installersListPageUrl,
    elements: [],
  },
  {
    key: "installer_associations",
    label: associationsInstallersTitle,
    link: installersAssociationsUrl,
    icon: <img alt={"--"} src={people} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "update_subscription",
    label: updateSubscriptionTitle,
    link: subscriptionsListToUpdateUrl,
    icon: <img alt={"--"} src={change} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "create_wire",
    label: addWireTitle,
    link: addWireUrl,
    icon: <img alt={"--"} src={wires} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "update_wire",
    label: updateWireTitle,
    link: wireListToUpdateWireUrl,
    icon: <img alt={"--"} src={wires} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "update_user",
    label: updateUserTitle,
    link: customersListPageUrl,
    icon: <img alt={"--"} src={user} className={"menu-icon"} />,
    elements: [],
  },
];

export const adminMenuItems: MenuType[] = [
  {
    key: "real_time_data",
    label: dataRealTimeTitle,
    icon: <img alt={"--"} src={dataRealTime} className={"menu-icon"} />,
    elements: [],
    link: subscriptionsListToInstallationPageUrl,
  },
  {
    key: "data_history",
    label: dataHistoryTitle,
    icon: <img alt={"--"} src={dataHistory} className={"menu-icon"} />,
    elements: [],
    link: subscriptionsListToDataHistoryPageUrl,
  },
  {
    key: "alarms",
    label: alarmsTitle,
    link: alarmsPageUrl,
    icon: <img alt={"--"} src={alarm} className={"menu-icon"} />,
    elements: [],
  },
  {
    key: "wires",
    label: wiresListTitle,
    link: wiresListUrl,
    icon: <img alt={"--"} src={wires} style={{ width: "30px" }} />,
    elements: [],
  },
  {
    key: "admin",
    label: adminPanelTitle,
    link: adminPanelIconsUrl,
    icon: <img alt={"--"} src={admin} className={"menu-icon"} />,
    elements: adminItem,
  },
];
