import "./createInstaller.css"
import ButtonItem from "../../ButtonItem"
import ButtonConfItem from "../../ButtonConfItem"
import {Col, Form, Row} from 'antd';
import {AddUser as ApiAddUser} from "../../../api/services/userService"
import React, {useEffect, useState} from "react";
import '../../../App.css'
import CustomTitle from "../../CustomTitle";
import {createInstallerFields} from "./inputs";
import {roles} from "../costants";
import FormInput from "../../input/FormInput";
import FormSelect from "../../select/FormSelect";
import {countries} from "../../countries";
import {addInstallerTitle} from "../title";
import OperationResult from "../../OperationResult";

const CreateInstaller: React.FC = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);
    const [status, setStatus] = useState<"success" | "error" | null> (null);

	function handleWindowSizeChange() {
			setWidth(window.innerWidth)
            setHeight(window.innerHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])
    
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then(
            values => {
                ApiAddUser({
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    confirm_password: values.confirm_password,
                    role: roles.installer,
                    user_info: {
                        phone: values.customer_phone,
                        address: values.customer_address,
                        city: values.customer_city,
                        province: values.customer_province,
                        country: values.customer_country,
                        zip_code: values.customer_zip_code
                    }
                }).then(
                    res => {
                        if(res && res.user){
                            setStatus("success");
                            form.resetFields();
                        } else {
                            setStatus("error");
                        }
                    }
                ).catch((e)=>{
                    setStatus("error");
                    console.log(e)
                })
            }
        )               
    }
    
    const formItemLayout = {
        labelCol: {
            sm: {
                span: 8
            }
        },
        wrapperCol: {
            sm: {
                span: 20
            }
        }
    };

    if(status) {
        return(
            <OperationResult
                status={status}
                operation={"create"}
                entity={"user"}
            />
        )
    }
    return(
        <>
            <CustomTitle title={addInstallerTitle}/>
            <div className="new-installer-form-container new-installer-form-container-responsive">
                <Form {...formItemLayout} layout="vertical" form={form} key={1}  name="user_panel" >
                    <Row gutter={24} key={"companyInfo"}>
                        {
                            createInstallerFields.map((el, index) => {
                                return (
                                    <Col span={(width <= 575 || height <= 815) ? 12 : 8} key={index}>
                                        {
                                            el.type !== "select" ? 
                                            <FormInput key={el.key + "forminput"} placeholder={el.label} type={el.type ?? "text"} keyValue={el.key} name={el.name} rules={el.rules} /> :
                                            <FormSelect key={el.key + "forminput"} placeholder={el.label} keyValue={el.key} name={el.name} options={countries} rules={el.rules} />
                                        }
                                    
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <div className="btn-container">
                        <ButtonConfItem buttonLabel="Reset" buttonOnConfirm={() => { form.resetFields(); }} buttonOnCancel={() => {}} questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?" /> 
                        <ButtonItem buttonType="primary" label="Invio" buttonOnClick={handleSubmit} />
                    </div>
                </Form>
            </div>
        </>
    )

}

export default CreateInstaller;