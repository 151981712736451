import './charts/timelineChart/timelineChart.css'
import '../../../App.css';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Form, Row} from 'antd';
import TitleWithBack from "../../../containers/TitleWithBack";
import InstallationSummary from '../../../containers/pages/installationPage/summaryCards/InstallationSummary';
import WiresSummary from '../../../containers/pages/installationPage/summaryCards/WiresSummary';
import AlarmSummary from '../../../containers/pages/installationPage/summaryCards/AlarmSummary';
import {Installation} from "../../../api/requests/installationsService";
import {getHistory, getInstallation} from "../../../api/services/installationsService";
import {dataRealTimeTitle} from '../title';
import RealTimeSummary from "./summaryCards/RealtimeSummary";
import InstallationSelect from './InstallationSelect';
import HistoryTable from './HistoryTable';
import {date} from './costant';
import { iFrameUrl } from '../costants';

type TimeSeriesProps = {
    installation_id: string,
    clearDataInterval: () => void,
    startHour: number,
    setStartHour: (h: number) => void,
    setStartDate: (start_date: string) => void,
    role: string
}

const InstallationPage: React.FC<TimeSeriesProps> = ({
    installation_id,
    clearDataInterval,
    setStartHour,
    startHour,
    setStartDate,
    role,
}) => {
    const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null);
    const [currentDate, setCurrentDate] = useState<any>(date[2]);
    const [history, setHistory] = useState<any[]>([]);
    const [historyInterval, setHistoryInterval] = useState<any>();

    const [form] = Form.useForm();

    useEffect(() => {
        getInstallation({id: installation_id}).then(
            res => {
                if (res && res.installation) {
                    setSelectedInstallation(res.installation);
                }
            }
        )
        return clearDataInterval
    }, []);


    const handlePeriodOrAggregationChange = useCallback((type: string) => {
        form.validateFields().then(
            values => {
                let startDate = new Date();
                startDate.setHours(startDate.getHours() - values.date);
                if(type === "date"){
                    const filter_date: any[] = date.filter(item => String(item.value) === String(values.date))
                    setCurrentDate(filter_date[0])
                    setStartDate(startDate.toISOString())
                    form.setFieldsValue({period: filter_date[0].period[0].value})
                }
            }
        )
	}, [date, form, installation_id]);

    const reqHistory = useCallback(() => {
        let d = new Date()
        d.setHours(d.getHours() - startHour)
        getHistory(installation_id, d.toISOString()).then((res) => {
            if(res && res.history){
                let filteredHistory: any = []
                res.history.forEach((hElem: {start: string})=> {
                    const startDateChart = new Date(new Date().setHours(new Date().getHours() - startHour))
                    if(hElem.start && new Date(hElem.start) < startDateChart){
                        const newElem = {
                            ...hElem,
                            start: startDateChart
                        }
                        filteredHistory = [...filteredHistory, newElem];
                    } else {
                        filteredHistory = [...filteredHistory, hElem];
                    }
                })
                setHistory(filteredHistory);
            } else {
                setHistory([])
            }
        })
    }, [startHour]);

    useEffect( () => {
        clearInterval(historyInterval);
        reqHistory();
        const intervalId = setInterval(reqHistory, 300000);
        setHistoryInterval(intervalId);

        return () => {
            clearInterval(historyInterval);
        }
    }, [startHour, reqHistory])

    document.body.style.background = "#f5f5f5c7"

    return (
        <>
            <TitleWithBack title={dataRealTimeTitle} key={"real_time_data"} />
            <InstallationSelect 
                currentDate={currentDate}
                date={date}
                form={form}
                setChange={handlePeriodOrAggregationChange}
                setStartHour={setStartHour}
            />
            <Row className="cards">
                <Col xs={24} xl={6} className="card-responsive"> <InstallationSummary selected_installation={selectedInstallation} /> </Col>
                <Col xs={24} xl={6} className="card-responsive"> <RealTimeSummary startHour={currentDate.value} selected_installation={selectedInstallation} /> </Col>
                <Col xs={24} xl={6} className="card-responsive"> <AlarmSummary startHour={currentDate.value} installation={selectedInstallation} /> </Col>
            </Row>
            <div className="wires-table">
                <WiresSummary installationId={installation_id} role={role} />
            </div>
            <div className="wires-table">
                <HistoryTable installation_id={installation_id} history={history} />
            </div>
            {
                //<TimelineChart title={historyTitle} history={history} />
            }

            {console.log("IFRAMEURL", iFrameUrl(installation_id))}
            <iframe
                title='grafana_iframe'
                id="grafana_iframe"
                className='responsive-iframe'
                src={iFrameUrl(installation_id)}
                style={{height: "1250px"}}
            />
        </>
    )
}

export default InstallationPage;