export const emailRules = [
    {
        required: true,
        message: "Inserisci l'email"
    },
    ({ getFieldValue }: any) => ({
        validator(value: any) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!value || re.test(getFieldValue('email')) || re.test(getFieldValue('pec')) ) {
                return Promise.resolve();
            }
            return Promise.reject('L\'email non è valida.');
        },
    })
]

export const passwordRules = [
    ({ getFieldValue }: any) => ({
        validator(value: any) {
            if (!value || (/.*\d.*/.test(getFieldValue('password')) && /.*[A-Z].*/.test(getFieldValue('password')) && /.*[a-z].*/.test(getFieldValue('password')) && /.*[~!].*/.test(getFieldValue('password')))) {
                return Promise.resolve();
            }
            
            return Promise.reject('La password deve contenere almeno un carattere minuscolo, uno maiuscolo, un numero e un carattere speciale.');
        },
    })
]

export const confirmPasswordRules = [
    ({ getFieldValue }: any) => ({
        validator(value: any) {
            if (!value || getFieldValue('password') === getFieldValue('confirm_password')) {
                return Promise.resolve();
            }
            
            return Promise.reject('Le due password non corrispondono!');
        },
    })
]
