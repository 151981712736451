import { emailRules } from "../../types/form/rules";
import { InputType } from "../../types/form/types";

export const customerInfo: InputType[] = [
    {
        key: "customer_name",
        name: "customer_name",
        label: "Nome",
        path: "name",
        rules: [ {required: true, message: 'Inserire nome!'} ]

    },
    {
        key: "customer_phone",
        name: "customer_phone",
        label: "Telefono",
        rules: [ {required: true, message: 'Inserire telefono!'} ]
,
        path: "phone",
        type: "number"
    },
    {
        key: "customer_address",
        name: "customer_address",
        label: "Indirizzo",
        path: "address",
        rules: [ {required: true, message: 'Inserire indirizzo!'} ]

    },
    {
        key: "customer_city",
        name: "customer_city",
        label: "Città",
        path: "city",
        rules: [ {required: true, message: 'Inserire città!'} ]

    },
    {
        key: "customer_province",
        name: "customer_province",
        label: "Provincia",
        path: "province",
        rules: [ {required: true, message: 'Inserire provincia!'} ]

    },
    {
        key: "customer_country",
        name: "customer_country",
        label: "Paese",
        path: "country",
        rules: [ {required: true, message: 'Inserire paese!'} ]

    },
    {
        key: "customer_zip_code",
        name: "customer_zip_code",
        label: "CAP",
        rules: [ {required: true, message: 'Inserire CAP!'} ]
,
        path: "zip_code",
        type: "number"
    }
]

export const companyInfo: InputType[] = [
    {
        key: "company_name",
        name: "company_name",
        path: "name",
        label: "Nome",
        rules: [ {required: true, message: 'Inserire Nome!'} ]

    },
    {
        key: "company_sdi",
        name: "company_sdi",
        path: "sdi",
        label: "SDI",
        rules: [ {required: true, message: 'Inserire SDI!'} ]

    },
    {
        key: "company_pec",
        name: "company_pec",
        label: "PEC",
        path: "pec",
        rules: emailRules

    },
    {
        key: "company_vat_number",
        name: "company_vat_number",
        label: "VAT",
        path: "vat_number",
        rules: [ {required: true, message: 'Inserire VAT!'} ]
,
        type: "number"
    },
    {
        key: "company_phone",
        name: "company_phone",
        label: "Telefono",
        path: "phone",
        rules: [ {required: true, message: 'Inserire telefono!'} ]
,
        type: "number"
    },
    {
        key: "company_address",
        name: "company_address",
        label: "Indirizzo",
        path: "address",
        rules: [ {required: true, message: 'Inserire indirizzo!'} ]

    },
    {
        key: "company_city",
        name: "company_city",
        label: "Città",
        path: "city",
        rules: [ {required: true, message: 'Inserire città!'} ]

    },
    {
        key: "company_province",
        name: "company_province",
        label: "Provincia",
        path: "province",
        rules: [ {required: true, message: 'Inserire provincia!'} ]

    },
    {
        key: "company_country",
        name: "company_country",
        label: "Paese",
        path: "country",
        rules: [ {required: true, message: 'Inserire paese!'} ]
,
        type: "select"
    },
    {
        key: "company_zip_code",
        name: "company_zip_code",
        label: "CAP",
        path: "zip_code",
        rules: [ {required: true, message: 'Inserire CAP!'} ]

    }
]