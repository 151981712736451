import './summary.css'
import React, {useCallback, useEffect, useState} from 'react';
import {Alert, Card} from 'antd';
import { Installation } from '../../../../api/requests/installationsService';
import { GetAlarms } from '../../../../api/services/installationsService';
import { alarm } from './costant';
import {localeDateTimeFormatter} from "../../../../utilities/utilities";
import {WarningOutlined} from "@ant-design/icons";
import { useHistory } from 'react-router';
import { alarmsPageUrl } from '../../costants';
type AlarmSummaryProps = {
    installation: Installation | null
    startHour: number
}

type Alarm = {
	installation_id: string          
	tag: string
	level: string
    info: {
	    rfid: string[],
        wire_name: string[],
        n_cards: number,
        usage_time: number,
        max_usage: number
    }
    start: string
    finish: string
}

const AlarmSummary: React.FC<AlarmSummaryProps> = ({ installation, startHour }) => {

    const [alarms, setAlarms] = useState<Alarm[]>([])

    const updateAlarms = useCallback(() => {
        const d = new Date();
        const start = new Date(new Date().setHours(d.getHours() - startHour)).toISOString();
        const end = d.toISOString();
        if(installation){
            GetAlarms(installation.id, start, end).then((res) => {
                if(res && res.alarms){
                    setAlarms(res.alarms)
                }else{
                    setAlarms([])
                }
            })
        }
    }, [startHour, installation])

    useEffect(() => {
        updateAlarms();
        const intervalId = setInterval(updateAlarms, 60000)
        return () => clearInterval(intervalId)
    }, [startHour, updateAlarms]);

    const switchAlarm = (a: Alarm) => {
        switch(a.tag){
            case alarm.maxUsageOver90:
                return {
                    message: `Il cavo ${a.info.wire_name[0]} ha superato la soglia del 90%`,
                    type: "warning" as "warning"
                }
            case alarm.maxUsageOver75:
                return {
                    message: `Il cavo ${a.info.wire_name[0]} ha superato la soglia del 75%`,
                    type: "warning" as "warning"
                }
            case alarm.moreTwoCards:
                return {
                    message: `La macchina ha lavorato con più di due cartellini in data ${localeDateTimeFormatter(a.start)}`,
                    type: "error" as "error"
                }
            case alarm.zeroCards:

                return {
                    message: `La macchina ha lavorato senza cartellini in data ${localeDateTimeFormatter(a.start)}`,
                    type: "error" as "error"
                }
            case alarm.unregisteredWire:
                return {
                    message: `Il cartellino ${a.info.rfid[0]} non è registrato`,
                    type: "warning" as "warning"
                }
            case alarm.offline:
                return {
                    message: `La macchina è andata offline in data ${localeDateTimeFormatter(a.start)}`,
                    type: "warning" as "warning"
                }
        }
    } 

    const history = useHistory()

	return(
        <Card
            style={{minHeight: "290px", maxHeight: "290px", overflow: "scroll"}}
            hoverable
            //title={<Title level={4} className={"tableTitle"}>Allarmi<div style={{marginLeft: "20%", marginRight: "0%"}}><WarningOutlined /></div></Title>}
            title={<div className="test"> Allarmi </div>}
            bordered
            headStyle={{maxHeight: "4vh"}}
            extra={<WarningOutlined style={{fontSize: "medium"}} onClick={() => {history.push(alarmsPageUrl)}} />}
        >
            <div key={`container_div_3`}>
                {
                    alarms.map((a: Alarm, i: number) => {
                        const alarmResult = switchAlarm(a)
                        if (alarmResult){
                            return (
                                <div key={i} className="alarm-container">
                                    <Alert message={alarmResult.message} type={alarmResult.type} />
                                </div>
                            )
                        }
                    })
                }
            </div>
        </Card>
    )
}

export default AlarmSummary;