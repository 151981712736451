import "./Header.css"
import {logout} from '../../api/services/loginService';
import {useHistory} from 'react-router';
import React from "react";
import {routes} from "../pages/costants";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import {AppBar, Avatar, Hidden, IconButton, Menu, MenuItem, Toolbar} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {useStyles} from "../pages/muiStyle";
import clsx from 'clsx';
import logomin from "../../logo/logo_min.png";

type HeaderItemProps = {
    SetNavbarIsCollapsed: (collapsed: boolean) => void,
    SetLogged: (b: boolean) => void,
    navbar_collapsed: boolean
}

const HeaderItem: React.FC<HeaderItemProps> = ({SetNavbarIsCollapsed, SetLogged, navbar_collapsed}) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const history = useHistory()
    const logOut = () => {
		logout().then(res => {
			if(res!== undefined && res.status === "success"){
				history.push(res.location)
			    SetLogged(false);
			}
		})
	}

    const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return(
        <AppBar
            position="fixed"
            className={clsx(classes.appBar)}
        >
        <Toolbar className={"header"}>
            <Hidden
                smUp
                implementation={"css"}
            >
                <img src={logomin} alt={"logo"} style={{maxWidth: "40px"}} onClick={() => history.push(routes.mainPage)}/>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={() => {SetNavbarIsCollapsed(!navbar_collapsed)}}
                >
                    <MenuIcon />
                </IconButton>
            </Hidden>

            <section className={classes.rightToolbar}>
                <Avatar onClick={openMenu}  src="/broken-image.jpg" />
            </section>
            

            <Menu
                id="header-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => {history.push(routes.profile)}}> <PersonOutlineOutlinedIcon /> Profilo </MenuItem>
                <MenuItem onClick={logOut}> <PowerSettingsNewOutlinedIcon /> Logout </MenuItem>
            </Menu> 
            </Toolbar>
        </AppBar>
    )
}

export default HeaderItem;

/*
<div className={isMobile ? "header" : undefined}>
            <Hidden
                smUp
                implementation={"css"}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={() => {SetNavbarIsCollapsed(!navbar_collapsed)}}
                >
                    <MenuIcon />
                </IconButton>
            </Hidden>
            
            <Avatar onClick={openMenu} className={isMobile ? "account-icon-responsive" : "account-icon"} />

            <Menu
                id="header-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => {history.push(routes.profile)}}> <PersonOutlineOutlinedIcon /> Profilo </MenuItem>
                <MenuItem onClick={logOut}> <PowerSettingsNewOutlinedIcon /> Logout </MenuItem>
            </Menu> 
        </div>
*/