import { InputType } from "../../types/form/types"

export const userFields: InputType[] = [
    {
        key: "name",
        name: "name",
        label: "Nome",
        rules: [ {required: true, message: 'Inserire nome!'} ]
    },
    {
        key: "email",
        name: "email",
        label: "Email",
        rules: [ {required: true, message: 'Inserire email!'} ]
    },
    {
        key: "phone",
        name: "phone",
        label: "Telefono",
        path: "phone",
        rules: [ {required: true, message: 'Inserire telefono!'} ]
,
        type: "number"
    },
    {
        key: "address",
        name: "address",
        label: "Indirizzo",
        path: "address",
        rules: [ {required: true, message: 'Inserire indirizzo!'} ]

    },
    {
        key: "city",
        name: "city",
        label: "Città",
        path: "city",
        rules: [ {required: true, message: 'Inserire città!'} ]

    },
    {
        key: "province",
        name: "province",
        label: "Provincia",
        path: "province",
        rules: [ {required: true, message: 'Inserire provincia!'} ]

    },
    {
        key: "country",
        name: "country",
        label: "Paese",
        path: "country",
        rules: [ {required: true, message: 'Inserire paese!'} ],
        type: "select"
    },
    {
        key: "zip_code",
        name: "zip_code",
        label: "CAP",
        path: "zip_code",
        rules: [ {required: true, message: 'Inserire CAP!'} ]
    }
]
