import moment from "moment";
import {LastWiresElement} from "../../../api/requests/installationsService";
import {dateTimeType, dateType, stringType, tagType} from "../../../utilities/utilities";
import {ResponsiveTableCell} from "../../responsiveTable";
import CustomTag from "./summaryCards/customTag";
import {DateType, PeriodType} from "./summaryCards/costant";
import {MUISortOptions} from "mui-datatables";

export type History = {
    installation_id: string
    start: string
    finish: string
    state: string
    info: {
        wires_name: string[],
        alarm: string
    }
}

const stateToColor = (s: string) => {
    switch (s) {
        case "Standby":
            return "grey"
        case "1 card" || "2 cards":
            return "green"
        default:
            return "red"
    }
}

const checkStatus = (status: string) => {
    switch (status) {
        case 'No cards':
            return "Nessun Cartellino"
        case '1 card':
            return "1 Cartellino"
        case '2 cards':
            return "2 Cartellini"
        case 'Più di 2 cards':
            return "2+ Cartellini"
        case 'Standby':
            return "Standby"
        case 'Card sconosciuto':
            return "Cartellino Non Registrato"
        case 'Sopra soglia':
            return "Superato Utilizzo Massimo"
        default:
            return ""
    }
}

const checkAlarm = (status: string) => {
    switch (status) {
        case 'no_cards':
            return "Nessun Cartellino"
        case 'more_two_cards':
            return "Più Di Due Cartellini"
        case 'max_usage':
            return "Utilizzo massimo raggiunto"
        case 'unregistered_wire':
            return "Cartellino Non Registrato"
        default:
            return ""
    }
}

export const workingHistoryTableColumn = (lastHistory: string | null, handleOpen: () => void, setSelectedWire: any, wires: LastWiresElement[]) => [
    {
        label: "Inizio",
        name: "start",
        options: {
            sort: true,
            filter: false,
            sortOrder: 'desc',
            sortCompare: (order: MUISortOptions['direction']) => {
                return (v1: {data: string}, v2: {data: string}) => {
                    let val1 = new Date(v1.data).getTime();
                    let val2 = new Date(v2.data).getTime();
                    return (val1 - val2) * (order === "asc" ? 1 : -1);
                };
            },
            customBodyRender: (value: string) => <ResponsiveTableCell type={dateTimeType} value={value}/>
        }
    },
    {
        label: "Fine",
        name: "finish",
        options: {
            sort: true,
            filter: false,
            sortCompare: (order: MUISortOptions['direction']) => {
                return (v1: {data: string}, v2: {data: string}) => {
                    let val1 = new Date(v1.data).getTime();
                    let val2 = new Date(v2.data).getTime();
                    return (val1 - val2) * (order === "asc" ? 1 : -1);
                };
            },
            customBodyRender: (value: string, data: any) => {
                const start = data.rowData[0]
                if(lastHistory && new Date(start).getTime() === new Date(lastHistory).getTime()){
                    return <ResponsiveTableCell type={tagType} value={"In corso"} color="green" />
                }else{
                    return <ResponsiveTableCell type={stringType} value={moment(new Date(value)).format("DD/MM/YYYY HH:mm")} />
                }
            }
        }
    },
    {
        label: "Durata (hh:mm:ss)",
        name: "start",
        options: {
            sort: false,
            filter: false,
            customBodyRender: (value: string, data: any) => {
                const finish = data.rowData[1]
                const time = new Date(finish).getTime() - new Date(value).getTime();
                const h: number = Math.floor(time/1000/60/60);
                const m: number = Math.floor((time/1000/60/60 - h)*60);
                const s: number = Math.floor((((time/1000/60/60 - h)*60) - m)*60);
                let hhmm: string = "";
                if (h.toString().length === 1){
                    hhmm = hhmm + `0${h}:`
                }else{
                    hhmm = hhmm + `${h}:`
                }
                if(m.toString().length === 1){
                    hhmm = hhmm + `0${m}:`
                }else{
                    hhmm = hhmm + `${m}:`
                }
                if(s.toString().length === 1){
                    hhmm = hhmm + `0${s}`
                }else{
                    hhmm = hhmm + `${s}`
                }
                return <ResponsiveTableCell type={stringType} value={hhmm.toString()} />
            }
        }
    },
    {
        label: "Stato",
        name: "status",
        options: {
            sort: true,
            filter: true,
            customBodyRender: (value: string) => <div className="cell">
                <CustomTag text={checkStatus(value)} color={stateToColor(value)} />
            </div>
        }
    },
    {
        label: "Dettagli",
        name: "info",
        options: {
            sort: true,
            filter: false,
            customBodyRender: (value: any) => {
                if(value?.alarm){
                    return <ResponsiveTableCell type={stringType} value={checkAlarm(value.alarm)}/>
                }else{
                    return <div style={{display: "flex", justifyContent: "space-between"}}>
                        {  
                            (value?.wires_name || [] as String[]).map((el: any) => {
                                return <a 
                                    href="#" 
                                    onClick={() => {
                                        let tmp_wires = wires.filter(w => w.name === el)
                                        setSelectedWire(tmp_wires[0]); 
                                        handleOpen();
                                    }}
                                > 
                                    {el}
                                </a>
                            })
                        }
                    </div>
                }
            } 
        }
    },
]

export const historyTitle: string = "Cicli di Lavorazione";

export const shortPeriod: PeriodType[] = [
    {value: "300000-5m", label: "5 minuti"},
    {value: "1800000-30m", label: "30 minuti"},
    {value: "3600000-1h", label: "1 ora"}
]
export const longPeriod: PeriodType[] = [
    {value: "3600000-1h", label: "1 ora"},
    {value: "10800000-5h", label: "5 ore"},
    {value: "36000000-10h", label: "10 ore"}
]
export const date: DateType[] = [
    {value: 2, label: "Ultime 2 Ore", period: shortPeriod},
    {value: 4, label: "Ultime 4 Ore", period: shortPeriod},
    {value: 24, label: "Ultimo Giorno", period: shortPeriod},
    {value: 48, label: "Ultimi 2 Giorni", period: shortPeriod},
    {value: 168, label: "Ultima Settimana", period: longPeriod},
    {value: 720, label: "Ultimo Mese", period: longPeriod},
    {value: 1440, label: "Ultimi 2 Mesi", period: longPeriod},
    {value: 2160, label: "Ultimi 3 Mesi", period: longPeriod}
]