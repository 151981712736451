import './charts/timelineChart/timelineChart.css'
import '../../../App.css'
import React, {useEffect, useState} from 'react';
import {Form, Select} from 'antd';
import {DateType} from './summaryCards/costant';

const { Option } = Select;

type InstallationSelectProps = {
    setStartHour: any
    setChange: any
    date: DateType[]
    currentDate: DateType
    form: any
}

const InstallationSelect: React.FC<InstallationSelectProps> = ({
    setStartHour,
    setChange,
    date,
    currentDate,
    form
}) => {
    const [width, setWidth] = useState<number>(window.innerWidth);
	function handleWindowSizeChange() {
			setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

    return (
        <>
            <Form layout={width > 575? "vertical" : "vertical"} className={ width > 575 ? "my-select-container" : undefined} key={1} form={form} name="installation_panel">
                <Form.Item className={width > 575 ? "my-select" : undefined} label={"Periodo"} name={"date"} initialValue={24}>
                    <Select
                        size="large"
                        placeholder={"..."}
                        onChange={(e) => {
                            setStartHour(Number(e));
                            setChange("date");
                        }}
                    >
                        {
                            date.map((el, index) => {
                                return <Option value={el.value} key={index}> {el.label} </Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Form>
        </>
    )
}

export default InstallationSelect;