import './summary.css';
import React, {} from 'react';
import {LastWiresElement} from "../../../../api/requests/installationsService";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { wiresModalComponents } from './costant';

type WiresModalProps = {
    wire: LastWiresElement | null
    open: boolean
    handleClose: () => void
}

const WiresModal: React.FC<WiresModalProps> = ({ wire, open, handleClose }) => {

    if(!wire){
        return null
    }

	return(
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"> Informazioni cavo {wire.name} </DialogTitle>
        <DialogContent>
            <TableContainer>
                    <Table size="small" aria-label="simple table">
                        <TableBody>
                            {
                                wiresModalComponents(wire).map((w) => {
                                    return <TableRow key={w.key}>
                                        <TableCell component="th" scope="row" style={{border: "none"}}> {w.title} </TableCell>
                                        <TableCell align="left" style={{border: "none"}}> {w.property} </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                OK
            </Button>
        </DialogActions>
        </Dialog>
        </>
    )
}

export default WiresModal;