import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { Action } from '../../../actions'
import { Store } from '../../../reducers'
import WireListTable from '../../../components/pages/wiresList/WireListTable'

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
}

const mapStateToProps = (state: Store) => {
    return {
        role: state.info.role
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WireListTable)